import React from 'react'
// import Customer_header from './Customer_header'
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
import Select from 'react-select';
// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';
import exportValue from '../../apiconfig'

// import Footer from './Footer'
// import { Tooltip as ReactTooltip } from "react-tooltip";
import axios from "axios";
import Swal from 'sweetalert2'
import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import Sub_header from '../Sub_header';
// import config from "../config"
// import Moment from 'react-moment';
// import Header_nav from './Header_nav';
// import download from 'downloadjs'

const My_address = () => {
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
    const [defaultCountry, setDefaultCountry] = React.useState({country_id:0})
    const [selectedOption, setSelectedOption] = React.useState(null);
    const [options, setOptions] = React.useState([]);
    const [modalState , setModalState] = React.useState({show:false})
    const [countryList, setcountryList] = React.useState([]);
    const [stateList, setStateList] = React.useState([]);
    const [cityList, setCityList] = React.useState([]);
    const [pincodeList, setPincodeList] = React.useState([]);
    const[addressState, setaddressState] = React.useState([]);
    const [addstate,setaddState] = React.useState({full_name:"" , country_name:"" ,state_name:"" , city_name:"" , pincode:"" , address:"" , email:"" , mobile:"" , warehouse_name:"", err:{} });
    console.log("addstate ===== " , addstate)
    const[country,setCountry] = React.useState({country_id:""})
    const[state,setState] = React.useState({state_id:""})
    const[city,setCity] = React.useState({city_id:""})
    const[editmodal , seteditmodal] = React.useState({show:false , full_name:"" , country_name:"" ,state_name:"" , city_name:"" , pincode:"" , address:"" , email:"" , mobile:"" , warehouse_name:""  , address_id:""  , country_id:"" , state_id:"" , city_id:"" , err:{}})
    console.log("editmodal===== " , editmodal)
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    const homeSection = document.querySelector('.home-section');
    if (homeSection) {
        homeSection.classList.toggle('sidebar-closed');
    }
};
  
React.useEffect(()=>{
    getCountryList()
    get_country_list()
    address_book_detail()
},[])
    const onChangeLocationGeneral = (e) => {
        console.log("e general name ",e.target.name);
         console.log("e general value ",e.target.value);
        
         setCountry({country_id:e.target.value})
         setaddState({...addstate , country_name:e.target.value})
        //    getStateList(e.target.value)
       
      }
      const onChangeLocationGeneraledit = (e) => {
        console.log("e general name ",e.target.name);
         console.log("e general value ",e.target.value);
        
         setCountry({country_id:e.target.value})
         seteditmodal({...editmodal , country_name:e.target.value})
        //    getStateList(e.target.value)
       
      }
      const onChangeLocationstate = (e) => {
        console.log("e general name ",e.target.name);
         console.log("e general value ",e.target.value);
         
         
       let values =   Object.assign({},e.target.value.split(',') )
       console.log("first",values[0])
       setState({state_id:values[0]})
       setaddState({...addstate , state_name:values[0]})
        getCityList({state_id:values[0], country_id:values[1]})
      
       
      }
      const onChangeLocationstateedit = (e) => {
        console.log("e general name ",e.target.name);
         console.log("e general value ",e.target.value);
         
         
       let values =   Object.assign({},e.target.value.split(',') )
       console.log("first",values[0])
       setState({state_id:values[0]})
       seteditmodal({...editmodal , state_id:values[0], state_name:values[0]})
       setCityList([])
       setOptions([])
       setSelectedOption(null)
        getCityList({state_id:values[0], country_id:values[1]})
      
       
      }
      const onChangeLocationcity = (e) => {
        console.log("e general name ",e.target.name);
         console.log("e general value ",e.target.value);
        setCity({city_id:e.target.value})
        setaddState({...addstate , city_name:e.target.value})
        setSelectedOption(null)
        setOptions([])
        getPincodeList(e.target.value)
      
       
      }
      const onChangeLocationcityedit = (e) => {
        console.log("e general name ",e.target.name);
         console.log("e general value ",e.target.value);
        setCity({city_id:e.target.value})
        seteditmodal({...editmodal , city_id:e.target.value , city_name:e.target.value})
        getPincodeList(e.target.value)
        setSelectedOption(null)
        setOptions([])
      
       
      }
      const getPincodeList = (value) => {
      
        console.log("value",value)
        let url = 'https://backend.shipdartexpress.com/v.1.0.1/web/query/country/pincode_list';
           let sendData = { city_id: value };
           console.log("bb", sendData);
           axios.post(url, sendData, {headers:exportValue.headers}).then((res) => {
               // console.log("city  ", res);           
               setPincodeList(res.data.output)
               let data = res.data.output
               const transformedOptions = data.map(item => ({
                value: item.postal_code, // Set the value property based on your data
                label: item.postal_code // Set the label property based on your data
              }));
           setOptions(transformedOptions)
       
       
           }).catch((e) => {
       
       
           });
       }
      const getCityList = (value) => {
      
       console.log("value",value)
       let url = 'https://backend.shipdartexpress.com/v.1.0.1/web/query/country/city_list';
          let sendData = { state_id: value.state_id,country_id:value.country_id, limit: 1000 };
          console.log("bb", sendData);
          axios.post(url, sendData, {headers:exportValue.headers}).then((res) => {
              // console.log("city  ", res);           
              setCityList(res.data.output)
      
      
          }).catch((e) => {
      
      
          });
      }
      const getCountryList = () => {


        let url = 'https://backend.shipdartexpress.com/v.1.0.1/web/query/country/country_list';
        let sendData = {limit:300 , status:1};
        // console.log("bb", sendData);
        axios.post(url, sendData, {headers:exportValue.headers}).then((res) => {
            // console.log("res ", res);           
            setcountryList(res.data.output)
    
    
        }).catch((e) => {
    
    
        });
    }
    const getStateList = (country_id) => {


        let url = 'https://backend.shipdartexpress.com/v.1.0.1/web/query/country/state_list';
        let sendData = { t_country_id: country_id, limit: 1000 };
        //  console.log("bb", sendData);
        axios.post(url, sendData, {headers:exportValue.headers}).then((res) => {
            // console.log("state ", res);           
            setStateList(res.data.output)
    
    
        }).catch((e) => {
    
    
        });
    }

    const handleChange =(e)=>{
       setaddState({...addstate, [e.target.name]:e.target.value , err:{}})
       
    }

    const addAddress = (value) => {
      
        console.log("value",value)
        let url = 'https://backend.shipdartexpress.com/v.1.0.1/web/query/country/add_address_book';
           let sendData = { addstate, customer_id: userData.customer_id };
           console.log("bb", sendData);
           let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

           if ( re.test(addstate.email) ) {
           if(addstate.full_name!="" && addstate.country_name!="" && addstate.state_name!="" && addstate.city_name!="" && addstate.address!="" && addstate.pincode!="" && addstate.email!="" && addstate.mobile!="" && addstate.warehouse_name!="" ){
           axios.post(url, sendData, {headers:exportValue.headers}).then((res) => {
             if(res.data.status==true){
                setModalState({...modalState,show:false})
                setOptions([])
                setSelectedOption(null)
                address_book_detail()
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                  })
                  
                  Toast.fire({
                    background:"rgb(231, 91, 75)",
                    type: 'success',
                    title: "Address Added Successfully",
                    color:"white"
                  });
                
             }
           }).catch((e) => {
            setModalState({...modalState,show:false})

            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              
              Toast.fire({
                background:"rgb(231, 91, 75)",
                type: 'unsuccess',
                title: "Something Went Wrong",
                color:"white"
              });
       
           });
        }
        
        else{
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              
              Toast.fire({
                background:"rgb(231, 91, 75)",
                type: 'unsuccess',
                title: "Please Fill All the Details",
                color:"white"
              });
        }
    }else{
        let itemsR = {...addstate};
        itemsR.err = {};
          itemsR.err["email"] = true;   
         setaddState(itemsR)
    }
       }

       const address_book_detail = ()=>{
        let url = 'https://backend.shipdartexpress.com/v.1.0.1/web/query/country/address_book';
        let sendData = {  customer_id: userData.customer_id };
        console.log("bb", sendData);
        axios.post(url,sendData ,{headers:exportValue.headers}).then((res)=>{
            if(res.data.status==true){
                console.log("sasdfdsa")
                setaddressState(res.data.output)
                setState([])
                setCityList([])
                setPincodeList([])
                setaddState({...addstate, full_name:"" ,  state_name:"" , city_name:"" , pincode:"" , address:"" , email:"" , mobile:"" , warehouse_name:"", err:{} })
                setOptions([])

                 
            }

        }).catch(()=>{
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              
              Toast.fire({
                background:"rgb(231, 91, 75)",
                type: 'unsuccess',
                title: "Something Went Wrong",
                color:"white"
              });
       
           
        })
       }

       const delete_address_book = (value)=>{
        let url = 'https://backend.shipdartexpress.com/v.1.0.1/web/query/country/delete_address_book';
        let sendData = {  customer_id: userData.customer_id , address_id:value };
        axios.post(url , sendData ,{headers:exportValue.headers}).then((res)=>{
            if(res.data.status==true){
                address_book_detail()
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                  })
                  
                  Toast.fire({
                    background:"rgb(231, 91, 75)",
                    type: 'success',
                    title: "Deleted Successfully",
                    color:"white"
                  });
            }

        }).catch(()=>{
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              
              Toast.fire({
                background:"rgb(231, 91, 75)",
                type: 'unsuccess',
                title: "Something Went Wrong",
                color:"white"
              });
        })
       }

       const setAsPrimary =(value)=>{
        let url = 'https://backend.shipdartexpress.com/v.1.0.1/web/query/country/update_address_book_primary_status';
        let sendData = {  customer_id: userData.customer_id , address_id:value };
        axios.post(url , sendData ,{headers:exportValue.headers}).then((res)=>{
            if(res.data.status==true){
                address_book_detail()
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                  })
                  
                  Toast.fire({
                    background:"rgb(231, 91, 75)",
                    type: 'success',
                    title: "Update Successfully",
                    color:"white"
                  });
            }

        }).catch(()=>{
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              
              Toast.fire({
                background:"rgb(231, 91, 75)",
                type: 'unsuccess',
                title: "Something Went Wrong",
                color:"white"
              });
        })
       }

       const handleEditChange = (event) => {
        // event.persist()
         console.log("event is v",event.target.name);
         seteditmodal({
             ...editmodal,
             [event.target.name]: event.target.value,
         })
     }

     const Seteditaddress = (sub)=>{
        seteditmodal({show:true, address_id:sub.address_id,full_name:sub.full_name , country_name:sub.country_name ,state_name:sub.state_name , city_name:sub.city_name , pincode:sub.pincode , address:sub.address , email:sub.email , mobile:sub.mobile , warehouse_name:sub.warehouse_name , country_id:sub.country_id , state_id:sub.state_id , city_id:sub.city_id })
        // getStateList(sub.country_id)
     }

     const editAddress =()=>{
        // console.log("value",value)
        let url = 'https://backend.shipdartexpress.com/v.1.0.1/web/query/country/edit_address_book';
           let sendData = { editmodal, customer_id: userData.customer_id };
           console.log("bb", sendData);
           let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

           if ( re.test(editmodal.email) ) {
           if(editmodal.full_name!="" && editmodal.country_name!="" && editmodal.state_name!="" && editmodal.city_name!="" && editmodal.address!="" && editmodal.pincode!="" && editmodal.email!="" && editmodal.mobile!="" && editmodal.warehouse_name!="" ){
           axios.post(url, sendData, {headers:exportValue.headers}).then((res) => {
             if(res.data.status==true){
                seteditmodal({...editmodal,show:false})
                setSelectedOption(null);
                setOptions([])
                setState([]);
                setCityList([]);
                setPincodeList([]);
                setCountry({country_id:""});
                setState({state_id:""});
                setCity({city_id:""});
                address_book_detail()
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                  })
                  
                  Toast.fire({
                    background:"rgb(231, 91, 75)",
                    type: 'success',
                    title: "Address Updated Successfully",
                    color:"white"
                  });
                
             }
           }).catch((e) => {
            seteditmodal({...editmodal,show:false})

            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              
              Toast.fire({
                background:"rgb(231, 91, 75)",
                type: 'unsuccess',
                title: "Something Went Wrong",
                color:"white"
              });
       
           });
        }
        
        else{
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              
              Toast.fire({
                background:"rgb(231, 91, 75)",
                type: 'unsuccess',
                title: "Please Fill All the Details",
                color:"white"
              });
        }
    }else{
        let itemsR = {...editmodal};
        itemsR.err = {};
          itemsR.err["email"] = true;   
         seteditmodal(itemsR)
    }
       
     }

     const get_country_list = async (value) => {
        // const value = e.target.value;
         
         console.log("value--------.....", value)
        
    
         let url = 'https://backend.shipdartexpress.com/v.1.0.1/web/query/country/country_list';
     
         // console.log("headers => ", config.headers);
     
         let senddata = {}
        
         axios.post(url, senddata, {headers:exportValue.headers})
           .then((responseJson) => {
              console.log("responseJson => ", responseJson.data.defaultCountry);
        
             setDefaultCountry(responseJson.data.defaultCountry)
             setCountry({country_id:responseJson.data.defaultCountry})
             setaddState({...addstate , country_name:responseJson.data.defaultCountry})
               getStateList(responseJson.data.defaultCountry)
        
           })
           .catch((error) => {
             //Hide Loader
     
             console.error(error);
           });
       };
       const handleSelectChange = selectedOption => {
        setSelectedOption(selectedOption);
        setaddState({...addstate, pincode:selectedOption})
      };
      const handleSelectChange1 = selectedOption => {
        setSelectedOption(selectedOption);
        seteditmodal({...editmodal, pincode:selectedOption})
      };

       const onhide=()=>{
        setModalState({ show: false })
        setaddState({...addstate, full_name:""  ,state_name:"" , city_name:"" , pincode:"" , address:"" , email:"" , mobile:"" , warehouse_name:"", err:{} })
         setSelectedOption(null)
        setOptions([])
        setState([])
        setCityList([])
        setPincodeList([])
        setCountry({country_id:""})
        setState({state_id:""})
        setCity({city_id:""})

       }
      
  return (
    <div>
<Modal show={modalState.show} onHide={() => onhide()} size='lg'>
<Modal.Header closeButton>
                    <Modal.Title>Add Address</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                <div className='row'>
                    <div className='col-6'>
                        <label>Full Name <span class="text-danger">*</span></label>
                        <input className='form-control mt-2' type='text' placeholder='Enter Full Name' name="full_name" autoComplete='off' onChange={(e)=>handleChange(e)}/>
                    </div>
                    <div className='col-6'>
                        <label>Warehouse Name <span class="text-danger">*</span></label>
                        <input className='form-control mt-2' type='text' placeholder='Enter Warehouse Name' name="warehouse_name" autoComplete='off' onChange={(e)=>handleChange(e)}/>
                    </div>
                  
                </div>
                <div className='row mt-3'>
                    <div className='col-3 country' >
                    <label for="" class="form-label">Select Country: <span class="text-danger">*</span></label>
          <select class="form-select" name="country_name" onChange={(e)=>onChangeLocationGeneral(e)}>
            <option>Select</option>
            {countryList.map((item)=>{
            return (
              <option value={item.country_id}>{item.country_name}</option>
          )
           
})}
</select>
                    </div>
                    <div className='col-3'>
                    <label for="" class="form-label">Select State: <span class="text-danger">*</span></label>
          <select class="form-select"  name="state_name" onChange={(e)=>onChangeLocationstate(e)}>
            <option>Select</option>
            {stateList.map((item)=>{
            return (
              <option value={[item.state_id,item.country_id]}>{item.state_name}</option>
          )
           
})}
          
          </select>
                    </div>
                    <div className='col-3'>
                    <label for="" class="form-label">Select City: <span class="text-danger">*</span></label>
                    <select class="form-select" name="city_name" onChange={(e)=>onChangeLocationcity(e)}>
            <option >Select</option>
            {cityList.map((item)=>{
            return (
              <option value={item.city_id}>{item.city_name}</option>
          )
           
})}
          </select>
                    </div>
                    <div className='col-3'>
                    <label for="" class="form-label">Select Pincode: <span class="text-danger">*</span></label>
                    {/* <select class="form-select" name="pincode" onChange={(e)=>handleChange(e)}>
            <option >Select</option>
            {pincodeList.map((item)=>{
            return (
              <option value={item.postal_code}>{item.postal_code}</option>
          )
           
})}
          </select> */}
      
      <Select
      
        options={options}
        value={selectedOption}
        onChange={(e)=>handleSelectChange(e)}
        isClearable={true}
        name="pincode"
      />
           
          
      
                    </div>

                </div>

                <div className='row mt-3'>
                    <div className='col-12'>
                        <label>Address <span class="text-danger">*</span></label>
                        <input className='form-control mt-2' placeholder='Enter Address' type='text' name="address" autoComplete='off' onChange={(e)=>handleChange(e)}/>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-6'>
                        <label>Email <span class="text-danger">*</span></label>
                        <input className='form-control mt-2' type='email' placeholder='Enter Email' name="email" autoComplete='off' onChange={(e)=>handleChange(e)} />
                        {(addstate.err.email)?
                            <>
                            <i class="fa fa-exclamation-triangle" style={{color:"rgb(167, 37, 20)"}} aria-hidden="true"></i><small style={{color:"rgb(167, 37, 20)" ,fontSize: "0.75rem",
                            lineHeight: "1.66667",
                            fontWeight: "400",
                            letterSpacing: "0.05em",marginLeft:"4px"}}>Please Fill Valid Email</small></>:""}
                    </div>
                    <div className='col-6'>
                        <label>Mobile # <span class="text-danger">*</span></label>
                        <input className='form-control mt-2' type='number' placeholder='Enter Mobile Number' name="mobile" autoComplete='off' onChange={(e)=>handleChange(e)}/>
                    </div>
                </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="danger" onClick={() => addAddress()}>
                        Submit
                    </Button>
                  <Button variant="primary" onClick={() => onhide()}>
                        Close
                    </Button>
                              
                </Modal.Footer>
            </Modal>  

            
  <Sub_header/>
<div class="sub_header_inner">
</div>

{/* {state.isLoading==true? */}
        <ReactLoading type="bubbles" color="#0d6efd" style={{ position: 'absolute', right: '45%', height: '20px', width: '10%' }} />:
        <section class="py-4">
  <div class="container">

    <div class="card mb-3">
      <div class="card-body">
        <header class="">
            <div class="row mb-4 mt-3">
                <div class="col col-md">
                    <div class="d-flex">
                        <div class="">
                            <h4><a href=""><i class="material-icons">chevron_left</i></a> My Address Book</h4>
                        </div>
                       
                    </div>
                    
                </div>
                <div class="col col-md-2 mb_display_none">
                    <div class="d-grid">
                        <a  class="btn btn-main btn-block" onClick={() => setModalState({ show: true })}> Add New Address</a>
                    </div>
                </div>
            </div>
            
        </header>
      </div>
    </div>

    

    <div class="card ">
      <div class="card-body">
     

        <table class="table  shipment_box table-responsive">
            <thead>
              <tr class="custom_row_mb">
                <th>Full Name</th>
                <th>Pincode</th>
                <th>Address</th>
                <th>City</th>
                <th>State</th>
                <th>Country</th>
                <th>Mobile#</th>
                <th>Email</th>
                <th>Action</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
             {addressState.map((sub)=>(
                        <tr class="custom_row_mb">
                        <td>
                             {sub.full_name}
                        </td>
                        
                        <td>
                          {sub.pincode}
                        
                        </td>


                        <td>
                            {sub.address}
                        </td>

                        
                        <td>
                            {sub.city_name}
                        </td>

                        <td>
                            {sub.state_name}
                        </td>


                        <td>
                           {sub.country_name}
                        </td>
                        
                        <td>
                        {sub.mobile}
                        </td>
                        <td>
                            {sub.email}
                        </td>
                        <td>
                       
                            <button type="button" class="btn btn-danger btn-sm" onClick={()=>delete_address_book(sub.address_id)}><i class="material-icons-outlined pin_delte fs-12">delete</i> </button>
                        
                        </td>
                        <td>
                            {sub.primary==true?<span className='badge bg-danger'>Primary</span>:<button className='btn btn-main btn-sm' onClick={()=>setAsPrimary(sub.address_id)}>Set as Primary</button>}
                        </td>
                        <td>
                        <a class="btn btn-danger btn-sm" onClick={()=>Seteditaddress(sub)} ><span class="fa fa-edit me-2"></span></a>
                        </td>
                        
                        </tr>
             ))}
               
               
            </tbody>
          </table>
      </div>
    </div>

         
  </div>
</section>
<Modal show={editmodal.show} onHide={() => seteditmodal({ show: false })} size='lg'>
<Modal.Header closeButton>
                    <Modal.Title>Edit Address</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                <div className='row'>
                    <div className='col-6'>
                        <label>Full Name <span class="text-danger">*</span></label>
                        <input className='form-control mt-2' type='text' placeholder='Enter Full Name' name="full_name" autoComplete='off' onChange={(e)=>handleEditChange(e)} value={editmodal.full_name}/>
                    </div>
                    <div className='col-6'>
                        <label>Warehouse Name <span class="text-danger">*</span></label>
                        <input className='form-control mt-2' type='text' placeholder='Enter Warehouse Name' name="warehouse_name" autoComplete='off' onChange={(e)=>handleEditChange(e)} value={editmodal.warehouse_name}/>
                    </div>
                  
                </div>
                <div className='row mt-3'>
                    <div className='col-3 country'>
                    <label for="" class="form-label">Select Country: <span class="text-danger">*</span></label>
          <select class="form-select" name="country_name" onChange={(e)=>onChangeLocationGeneraledit(e)}>
            <option >Select</option>
            {countryList.map((item)=>{
            return (
              <option value={item.country_id}>{item.country_name}</option>
          )
           
})}
</select>
                    </div>
                    <div className='col-3'>
                    <label for="" class="form-label">Select State: <span class="text-danger">*</span></label>
          <select class="form-select"  name="state_name" onChange={(e)=>onChangeLocationstateedit(e)} >
            <option>Select</option>
            {stateList.map((item)=>{
            return (
              <option value={[item.state_id,item.country_id]}>{item.state_name}</option>
          )
           
})}
          
          </select>
                    </div>
                    <div className='col-3'>
                    <label for="" class="form-label">Select City: <span class="text-danger">*</span></label>
                    <select class="form-select" name="city_name" onChange={(e)=>onChangeLocationcityedit(e)} >
            <option >Select</option>
            {cityList.map((item)=>{
            return (
              <option value={item.city_id}>{item.city_name}</option>
          )
           
})}
          </select>
                    </div>
                    <div className='col-3'>
                    <label for="" class="form-label">Select Pincode: <span class="text-danger">*</span></label>
                    <Select
      
        options={options}
        value={selectedOption}
        onChange={(e)=>handleSelectChange1(e)}
        isClearable={true}
        name="pincode"
      />
                    </div>

                </div>

                <div className='row mt-3'>
                    <div className='col-12'>
                        <label>Address <span class="text-danger">*</span></label>
                        <input className='form-control mt-2' placeholder='Enter Address' type='text' name="address" autoComplete='off' onChange={(e)=>handleEditChange(e)} value={editmodal.address}/>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-6'>
                        <label>Email <span class="text-danger">*</span></label>
                        <input className='form-control mt-2' type='email' placeholder='Enter Email' name="email" autoComplete='off' onChange={(e)=>handleEditChange(e)} value={editmodal.email}/>
                        {/* {(editmodal.err.email)?
                            <>
                            <i class="fa fa-exclamation-triangle" style={{color:"rgb(167, 37, 20)"}} aria-hidden="true"></i><small style={{color:"rgb(167, 37, 20)" ,fontSize: "0.75rem",
                            lineHeight: "1.66667",
                            fontWeight: "400",
                            letterSpacing: "0.05em",marginLeft:"4px"}}>Please Fill Valid Email</small></>:""} */}
                    </div>
                    <div className='col-6'>
                        <label>Mobile # <span class="text-danger">*</span></label>
                        <input className='form-control mt-2' type='number' placeholder='Enter Mobile Number' name="mobile" autoComplete='off' onChange={(e)=>handleEditChange(e)} value={editmodal.mobile}/>
                    </div>
                </div>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="danger" onClick={() => editAddress()}>
                        Submit
                    </Button>
                  <Button variant="primary" onClick={() => seteditmodal({ show: false })}>
                        Close
                    </Button>
                              
                </Modal.Footer>
            </Modal>  

{/* } */}
{/* <Footer/> */}
</div>
  )
}

export default My_address
