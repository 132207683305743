import React, { useState, useEffect } from "react";
import axios from "axios";
import { Notyf } from 'notyf';
import Swal from 'sweetalert2'
import { useNavigate } from "react-router-dom";
import exportValue from "../apiconfig";
import Left_panel from './Left_panel';
import Sub_header from './Sub_header';
import EChartsReact from "echarts-for-react";
import { useTheme } from '@material-ui/core/styles';
import {useSelector, useDispatch } from "react-redux";
import { DashboardLists } from "../redux/actions/DashboardActions";
import {CanvasJSChart} from 'canvasjs-react-charts'
import Chart from 'chart.js/auto'; 
import ReactLoading from 'react-loading';
const Index = () => {
    const { palette } = useTheme()
    let navigate = useNavigate();
    const loginDetail = localStorage.getItem('admin_login');  
    console.log("e => ", loginDetail);
    if(loginDetail == null) {
        console.log(" not valid ");
       // window.location.href = '/login';
        //navigate('/login',{ replace: true })
    }
    const notyf = new Notyf();
    
    const [state, setState] = useState({admin:[{total_amount:""}],users_find_count:"", count_vehicle_lists:"", count_transporterList: "", count_shipment: "",count_shipment_bids:"",count_spamReport:"",mon_shipment:"",tue_shipment:"",wed_shipment:"",thu_shipment:"",fri_shipment:"",sat_shipment:"",sun_shipment:"",mon_users:"",tue_users:"",wed_users:"",thu_users:"",fri_users:"",sat_users:"",sun_users:"",mon_payments:"",tue_payments:"",wed_payments:"",thu_payments:"",fri_payments:"",sat_payments:"",sun_payments:"",week_amount:""});
    const [states,setStates] = useState({isLoading:true});
  const[dimensionState,setdimensionState] = useState({companyDetail:[]})
  const [shipmentCount, setShipmentCount] = useState([]);
  const[last30daysshipment , setlast30daysshipment] = useState({Last30daysShipments:0 , todayShipment:0})
  console.log("shipmentCount == " , shipmentCount)
  const[isLoading , setIsLoading] = useState(false)
  const [orderCount, setOrderCount] = useState([]);
  const[last30daysorder, setlast30daysorder] = useState({Last30daysOrder:0 , todayOrder:0})
  console.log("shipmentCount == " , shipmentCount)
  const[isLoadingorder , setIsLoadingorder] = useState(false)
  const[isLoadingcarrier , setIsLoadingcarrier] = useState(false)
  const[isLoadingstatus , setIsLoadingstatus] = useState(false)


  const [carrierBookingData, setCarrierBookingData] = useState([]);
  const [statusBookingData, setSatusBookingData] = useState([]);
  const [walletTransactionData, setwalletTransactionData] = useState([]);
  const [spendtransactionData, setspendtransactionData] = useState([]);
  const[isLoadingtransaction , setIsLoadingtransaction] = useState(false)
  const [topcustomers, settopcustomers] = useState([]);
  const[isLoadingtopcustomer , setIsLoadingtopcustomer] = useState(false)
  const [valuedcustomers, setvaluedcustomers] = useState([]);
  const[isLoadingvaluedcustomer , setIsLoadingvaluedcustomer] = useState(false)
  const [tickets, settickets] = useState([]);
  const[IsLoadingtickets , setIsLoadingtickets] = useState(false)
  const[tochartstate , settopchartstate] = useState({shipment_count:0 , order_count:0 , user_count:0 , recharges:0})
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 7);

  // Format the start date and end date as strings
  const formattedStartDate = startDate.toISOString().split('T')[0];
  const formattedEndDate = new Date().toISOString().split('T')[0];
const[date,setdate]=useState({start_date:formattedStartDate , end_date:formattedEndDate})
  const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
      const homeSection = document.querySelector('.home-section');
      if (homeSection) {
          homeSection.classList.toggle('sidebar-closed');
      }
  };
useEffect(()=>{
  axios_get_api()
},[date])
  

//   const dispatch = useDispatch();
useEffect(() => {
  const shipment_count_chart = () => {
    setIsLoading(true)
    const full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/charts_for_dashboard`;
   

    const sendData = {
      startDate: date.start_date,
      endDate: date.end_date,
      type: "Shipment"
    };
   axios.post(full_api, sendData, { headers: exportValue.headers })
      .then(res => {
        
        setShipmentCount(res.data.counts);
        setlast30daysshipment({Last30daysShipments:res.data.last30days , todayShipment:res.data.todayShipment})
        console.log("Shipment count:", res.data);
        setIsLoading(false)
      })
      .catch((error) => {
        console.error("Error fetching shipment count:", error);
      });
  };

  shipment_count_chart();

  const orders_count_chart = () => {
    setIsLoadingorder(true)
    const full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/charts_for_dashboard`;
   

    const sendData = {
      startDate: date.start_date,
      endDate: date.end_date,
      type: "Order"
    };
   axios.post(full_api, sendData, { headers: exportValue.headers })
      .then(res => {
        
        setOrderCount(res.data.counts);
        setlast30daysorder({Last30daysOrder:res.data.last30days , todayOrder:res.data.todayShipment})
        console.log("Shipment count:", res.data);
        setIsLoadingorder(false)
      })
      .catch((error) => {
        console.error("Error fetching shipment count:", error);
      });
  };

  
  orders_count_chart();
  
  const carrier_chart = () => {
    setIsLoadingcarrier(true)
    const full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/charts_for_dashboard`;
  

    const sendData = {
      startDate: date.start_date,
      endDate: date.end_date,
      type: "Carrier"
    };
   axios.post(full_api, sendData, { headers: exportValue.headers })
      .then(res => {
       
        const data = res.data.carrierCounts; // Assuming the API returns an array of objects with 'carrier' and 'count' properties
        console.log("data ====== "  ,data)
        setCarrierBookingData(data);
        setIsLoadingcarrier(false)
      })
      .catch((error) => {
        console.error("Error fetching shipment count:", error);
      });
  };

  carrier_chart();

  const status_chart = () => {
    setIsLoadingstatus(true)
    const full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/charts_for_dashboard`;
   

    const sendData = {
      startDate: date.start_date,
      endDate: date.end_date,
      type: "Status"
    };
   axios.post(full_api, sendData, { headers: exportValue.headers })
      .then(res => {
       
        const data = res.data.statusCounts; // Assuming the API returns an array of objects with 'carrier' and 'count' properties
        console.log("data ======>>>>>>>> "  ,data)
        setSatusBookingData(data)
        setIsLoadingstatus(false)
      })
      .catch((error) => {
        console.error("Error fetching shipment count:", error);
      });
  };

  status_chart();
  const transaction_chart = () => {
     setIsLoadingtransaction(true)
    const full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/charts_for_dashboard`;
   

    const sendData = {
      startDate: date.start_date,
      endDate: date.end_date,
      type: "Transactions"
    };
   axios.post(full_api, sendData, { headers: exportValue.headers })
      .then(res => {
       
        const data = res.data.resultArray; // Assuming the API returns an array of objects with 'carrier' and 'count' properties
        console.log("setwalletTransactionData ======>>>>>>>> "  ,data)
        setwalletTransactionData(data)
        setspendtransactionData(res.data.resultArrayspend)
        setIsLoadingtransaction(false)
      })
      .catch((error) => {
        console.error("Error fetching shipment count:", error);
      });
  };

  transaction_chart();

  const top_customers_chart = () => {
    setIsLoadingtopcustomer(true)
   const full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/charts_for_dashboard`;
  

   const sendData = {
    startDate: date.start_date,
    endDate: date.end_date,
     type: "Top Customers"
   };
  axios.post(full_api, sendData, { headers: exportValue.headers })
     .then(res => {
      
       const data = res.data.result; // Assuming the API returns an array of objects with 'carrier' and 'count' properties
       console.log("top_customers ======>>>>>>>> "  ,data)
       settopcustomers(data)
       setIsLoadingtopcustomer(false)
     })
     .catch((error) => {
       console.error("Error fetching shipment count:", error);
     });
 };

 top_customers_chart();

 const valued_customers_chart = () => {
   setIsLoadingvaluedcustomer(true)
 const full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/charts_for_dashboard`;
 

 const sendData = {
  startDate: date.start_date,
      endDate: date.end_date,
   type: "Valued Customers"
 };
axios.post(full_api, sendData, { headers: exportValue.headers })
   .then(res => {
    
     const data = res.data.result; // Assuming the API returns an array of objects with 'carrier' and 'count' properties
     console.log("valued_customers ======>>>>>>>> "  ,data)
     setvaluedcustomers(data)
     setIsLoadingvaluedcustomer(false)
   })
   .catch((error) => {
     console.error("Error fetching shipment count:", error);
   });
};

valued_customers_chart();

const customers_tickets = () => {
  setIsLoadingtickets(true)
const full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/charts_for_dashboard`;


const sendData = {
  startDate: date.start_date,
  endDate: date.end_date,
  type: "Tickets"
};
axios.post(full_api, sendData, { headers: exportValue.headers })
  .then(res => {
   
    const data = res.data.find_tickets; // Assuming the API returns an array of objects with 'carrier' and 'count' properties
    console.log("find_tickets ======>>>>>>>> "  ,data)
    settickets(data)
    setIsLoadingtickets(false)
  })
  .catch((error) => {
    console.error("Error fetching shipment count:", error);
  });
};

customers_tickets();
}, [date]);
useEffect(() => {
  const ctx = document.getElementById('shipmentchart').getContext('2d');

  // Use actual shipment counts received from the API
  const data = {
    labels: shipmentCount.map(item => item.date),
    datasets: [{
      data: shipmentCount.map(item => item.count),
      borderColor: '#3D348B', // Line color
      borderWidth: 2,
      fill: true ,// Fill the area under the line
      backgroundColor: '#cbc7ea'
    }],
  };

  const options = {
    scales: {
      x: {
        display: false,
      },
      y: {
        beginAtZero: true,
        display: false,
      },
    },
    elements: {
      line: {
        tension: 0.4,
      },
      point: {
        radius: 1,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  const ordersChart = new Chart(ctx, {
    type: 'line',
    data: data,
    options: options,
  });

  return () => {
    ordersChart.destroy();
  };
}, [shipmentCount]);
      
      useEffect(()=>{
      const ctx = document.getElementById('orderchart').getContext('2d');

      // Generate random order values for the last 30 days
      const randomOrderValues = Array.from({ length: 30 }, () => Math.floor(Math.random() * 100));
  
      // Data for the line chart
      const data = {
        labels: orderCount.map(item => item.date),
    datasets: [{
      data: orderCount.map(item => item.count),// Random order values for the last 30 days
      borderColor: '#F18701', // Line color
      borderWidth: 2,
      fill: true ,// Fill the area under the line
      backgroundColor: '#febd67' // Fill color
        }]
      };
  
      // Configuration for the chart (without axis and grid lines)
      const options = {
        scales: {
          x: {
            display: false // Hide x-axis
          },
          y: {
            display: false // Hide y-axis
          }
        },
        elements: {
          line: {
            tension: 0.4 // Adjust the tension of the line (0.0 for straight lines, 1.0 for very smooth lines)
          },
          point: {
            radius: 1 // Set point radius to 0 to remove points
          }
        },
        plugins: {
          legend: {
            display: false // Hide legend
          }
        }
      };
  
      // Create the line chart
      const ordersChart = new Chart(ctx, {
        type: 'line',
        data: data,
        options: options
      });
  
      // Cleanup: Destroy the chart when the component unmounts
      return () => {
        ordersChart.destroy();
      };
    }, [orderCount]);
   // dashboardData
    const getDashboardCount = useSelector((state)=> state.dashboardData.dashboardTotalCount)
  
    console.log("one",getDashboardCount);
    if(getDashboardCount && states.isLoading == true){
         setStates({isLoading:false})
        setState(getDashboardCount)
    }

    // const GetDays = (d,Mention_today=false)=>{
    //     //Mention today mean the array will have today date 
    //     var DateArray = [];
    //     var days=d;
    //     for(var i=0;i<days;i++){
    //     if(!Mention_today && i==0){i=1;days+=1}
    //     var date = new Date();
    //     var last = new Date(date.getTime() - (i * 24 * 60 * 60 * 1000));
    //     var day =last.getDate();
    //     var month=last.getMonth()+1;
    //     var year=last.getFullYear();
    //     const fulld = (Number(day)) // Format date as you like
    //     DateArray.push(fulld);
    //     }
    //     return DateArray;
    //     }
    //    console.log(GetDays(5)) //Will get the past 5 days formated YY-mm-dd
      
   
      React.useEffect(() => {
       // dimension_detail();
      
       
       
      }, [])
      

      useEffect(() => {
        // Get the canvas element and its context
        const ctx = document.getElementById('shipmentChart').getContext('2d');
       
        // Data for the bar chart
        const data = {
          labels:  statusBookingData.map(entry => entry.status),
          datasets: [{
            label: 'Shipment Status',
            data:  statusBookingData.map(entry => entry.totalCount), // Your shipment data here
            backgroundColor: [
              '#c19efa', // Total Shipments color
              '#3D348B', // Total Shipments color
              '#F7B801',
              '#7678ED',
              '#F35B04', 
              '#F18701', 
              '#8338ec',
              'rgba(153, 102, 255, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(153, 102, 255, 0.2)',
            ],
            borderColor: [
              '#6610f2',
              '#302970',
              '#cb9801',
              '#1d21e2',
              '#c94c03',
              '#cb7301',
              '#6e16e9',
              '#6610f2',
              '#6610f2',
              '#6610f2',
            ],
            borderWidth: 1
          }]
        };
    
        // Configuration for the chart
        const options = {
          scales: {
            y: {
              beginAtZero: true // Start Y axis at 0
            }
          }
        };
    
        // Create the bar chart
        const shipmentChart = new Chart(ctx, {
          type: 'bar',
          data: data,
          options: options
        });
    
        // Cleanup: Destroy the chart when the component unmounts
        return () => {
          shipmentChart.destroy();
        };
      }, [statusBookingData]);

      useEffect(() => {
        const ctx = document.getElementById('carrierBookingChart2').getContext('2d');
    
        // Data for the pie chart
        const data = {
          labels: carrierBookingData.map(entry => entry.carrier),
          datasets: [{
            data: carrierBookingData.map(entry => entry.totalCount),
            backgroundColor: [
              '#3D348B', // DHL
      '#7678ED', // Fedex
      '#F7B801', // UPS
      '#F18701', // Aramex
      '#F35B04', // Delhivery
      '#8338ec' , // DTDC
              '#ED1522',
              '#1EA11A '
            ],
            // borderColor: ['rgba(255, 255, 255, 1)'].repeat(carrierBookingData.length),
            borderWidth: 1
          }]
        };
    
        const options = {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'right'
            }
          }
        };
    
        const carrierBookingChart = new Chart(ctx, {
          type: 'pie',
          data: data,
          options: options
        });
    
        return () => {
          carrierBookingChart.destroy();
        };
      }, [carrierBookingData]);

      useEffect(() => {
        // Get the canvas element and its context
        const ctx = document.getElementById('walletComparisonChart').getContext('2d');
    
        // Generate random data for amount recharged in wallet and user's spend wallet amount for the last 30 days
        const rechargedAmount = Array.from({ length: 30 }, () => Math.floor(Math.random() * 1000));
        const userSpendAmount = Array.from({ length: 30 }, () => Math.floor(Math.random() * 500));
    
        // Data for the line chart
        const data = {
          labels:  walletTransactionData.map(item => item.date), // Day labels
          datasets: [
            {
              label: 'Amount Recharged',
              data: walletTransactionData.map(item => item.totalAmount),
              borderColor: '#529ef2', // Line color for recharged amount
              backgroundColor: 'rgba(75, 192, 192, 0.2)', // Fill color for recharged amount
              borderWidth: 2,
              fill: true, // Fill the area under the line
              tension: 0.4 // Adjust the tension of the line (0.0 for straight lines, 1.0 for very smooth lines)
            },
            {
              label: 'User Spend Amount',
              data: spendtransactionData.map(item => item.totalAmount),
              borderColor: '#78cbbc', // Line color for user's spend amount
              backgroundColor: 'rgba(120, 203, 188, 0.2)', // Fill color for user's spend amount
              borderWidth: 2,
              fill: true, // Fill the area under the line
              tension: 0.4 // Adjust the tension of the line (0.0 for straight lines, 1.0 for very smooth lines)
            }
          ]
        };
    
        // Configuration for the chart
        const options = {
          scales: {
            y: {
              beginAtZero: true // Start Y axis at 0
            }
          }
        };
    
        // Create the line chart
        const walletComparisonChart = new Chart(ctx, {
          type: 'line',
          data: data,
          options: options
        });
    
        // Cleanup: Destroy the chart when the component unmounts
        return () => {
          walletComparisonChart.destroy();
        };
      }, [walletTransactionData]);

      const axios_get_api = () => {
    
        let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/top_chart_for_dashboard`;
       

   const sendData = {
    startDate: date.start_date,
      endDate: date.end_date,
   };
        
    
        axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
           
          console.log("response top", res);
          settopchartstate({...tochartstate , shipment_count:res.data.shipment_count , order_count:res.data.order_count , user_count:res.data.user_count , recharges:res.data.recharges})
        }).catch((e) => {
    
        //   toast.configure()
        //   toast.error("Some thing went wrong")
          console.log("----error:   ", e);
        })
      }

      const handleChange=(e)=>{
setdate({...date, [e.target.name]:e.target.value})
      }
  return (
    <div>
        
      

       
        {/* <Sub_header/> */}
{/* <div style={{marginLeft:"15px",marginRight:"15px"}}>        
<section class="pt-3 top_state">
            <div class="row">
                <div class="col-12 col-sm-6 col-xl-3 mb-4">
                    <div class="card border-0 shadow">
                        <div class="card-body">
                            <div class="row d-block d-xl-flex align-items-center">
                                <div class="col-6 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                    <div class="icon-shape icon-shape-success rounded me-4 me-sm-0">
                                        <i class="fa fa-cube fa-2x" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div class="col-6 col-xl-7 px-xl-0">
                                    <div class="d-none d-sm-block">
                                        <h2 class="h6 text-gray-400 mb-0">Shipments</h2>
                                        <h3 class="fw-extrabold mb-2">
                                            <a href="">45k</a>
                                        </h3>
                                    </div>
                                    <small class="d-flex align-items-center text-gray-500">
                                        Last 30 days
                                    </small> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-xl-3 mb-4">
                    <div class="card border-0 shadow">
                        <div class="card-body">
                            <div class="row d-block d-xl-flex align-items-center">
                                <div class="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                    <div class="icon-shape icon-shape-secondary rounded me-4 me-sm-0">
                                        <i class="fa fa-2x fa-users" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div class="col-12 col-xl-7 px-xl-0">
                                    <div class="d-none d-sm-block">
                                        <h2 class="h6 text-gray-400 mb-0">Users</h2>
                                        <h3 class="fw-extrabold mb-2">
                                            <a href="">736</a>
                                        </h3>
                                    </div>
                                    <small class="d-flex align-items-center text-gray-500">
                                        Last 30 days
                                    </small> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-xl-3 mb-4">
                    <div class="card border-0 shadow">
                        <div class="card-body">
                            <div class="row d-block d-xl-flex align-items-center">
                                <div class="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                    <div class="icon-shape icon-shape-info rounded me-4 me-sm-0">
                                        <i class="fa fa-2x fa-first-order" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div class="col-12 col-xl-7 px-xl-0">
                                    <div class="d-none d-sm-block">
                                        <h2 class="h6 text-gray-400 mb-0"> Orders</h2>
                                        <h3 class="fw-extrabold mb-2">
                                            <a href="">349</a>
                                        </h3>
                                    </div>
                                    <small class="text-gray-500">
                                    Last 30 days
                                    </small> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-xl-3 mb-4">
                    <div class="card border-0 shadow">
                        <div class="card-body">
                            <div class="row d-block d-xl-flex align-items-center">
                                <div class="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                    <div class="icon-shape icon-shape-danger rounded me-4 me-sm-0">
                                        <i class="fa fa-2x fa-usd" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div class="col-12 col-xl-7 px-xl-0">
                                    <div class="d-none d-sm-block">
                                        <h2 class="h6 text-gray-400 mb-0"> Recharges</h2>
                                        <h3 class="fw-extrabold mb-2">
                                            <a href="">$27093</a>
                                        </h3>
                                    </div>
                                    <small class="text-gray-500">
                                    Last 30 days
                                    </small> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        <section class="mb-3">
            <div class="row">
             
                <div class="col-12 col-md-6">
                    <div class="shipment_card_st shadow-sm">
                        <div class="shipment_card_st_bx">
                        {isLoading?
               <div style={{ left: "20%", top: "45%", position: "absolute" }}>
               <ReactLoading type={"spin"} color={"#000000"} height={100} width={30} />
           </div>
              :
                            <div class="row">
                                <div class="col-12 col-md">
                                    <h5>Shipment Information</h5>
                                    <h1>{last30daysshipment.todayShipment} <small>Today</small></h1>
                                    <h6>{last30daysshipment.Last30daysShipments} booked in last 30 days</h6>
                                </div>
                                <div class="col-12 col-md-4 text-end">
                                    <a href="">Shipment Report</a>
                                </div>
                            </div>
}
                        </div>
                        <canvas id="shipmentchart" width="400" height="100"></canvas>
                    </div>
                </div>
                
                <div class="col-12 col-md-6">
                    <div class="order_card_st shadow-sm">
                        <div class="order_card_st_bx">
                        {isLoadingorder?
               <div style={{ left: "70%", top: "45%", position: "absolute" }}>
               <ReactLoading type={"spin"} color={"#000000"} height={100} width={30} />
           </div>
              :
                            <div class="row">
                                <div class="col-12 col-md">
                                    <h5>Orders Information</h5>
                                    <h1>{last30daysorder.todayOrder} <small>Today</small></h1>
                                    <h6>{last30daysorder.Last30daysOrder} added in last 30 days</h6>
                                </div>
                                <div class="col-12 col-md-4 text-end">
                                    <a href="">Orders Report</a>
                                </div>
                            </div>
}
                        </div>
                        <canvas id="orderchart" width="400" height="100"></canvas>
                    </div>
                </div>
            </div>
        </section>

        <section class="mb-3">
            <div class="row">
                <div class="col-12 col-md-12">
                    <div class="card shadow-sm">
                      <div class="card-body">
                        <div class="card-body">
                            <div class="d-flex justify-content-between">
                                <div class="">
                                    <h6>Shipments</h6>
                                </div>
                                <div class="">
                                    <small>Last 30 days</small>
                                </div>
                            </div>
                            <div class="row">
                           
                             
                                {isLoadingstatus?
               <div style={{ left: "46%", top: "10%", position: "absolute" }}>
               <ReactLoading type={"spin"} color={"#000000"} height={100} width={30} />
           </div>
              :
                                <div class="col-12 col-md-12">
                                    <canvas id="shipmentChart"  width="400" height="100"></canvas>
                                </div>
}
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="mb-3">
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="card shadow-sm">
                      <div class="card-body">
                        <div class="card-body">
                        {isLoadingcarrier?
               <div style={{ left: "40%", top: "35%", position: "absolute" }}>
               <ReactLoading type={"spin"} color={"#000000"} height={100} width={30} />
           </div>
              :
                         <div class="d-flex justify-content-between">
                                <div class="">
                                    <h6>Carrier wise Shipments</h6>
                                </div>
                                <div class="">
                                    <small>Last 30 days</small>
                                </div>
                            </div>
}
                            <div class="row">
                                <div class="col-12">
                                    <canvas id="carrierBookingChart2" width="400" height="200"></canvas>
                                </div>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="card shadow-sm">
                      <div class="card-body">
                        <div class="card-body">
                        
                        {isLoadingtransaction?
               <div style={{ left: "40%", top: "35%", position: "absolute" }}>
               <ReactLoading type={"spin"} color={"#000000"} height={100} width={30} />
           </div>
              :
                            <div class="d-flex justify-content-between">
                                <div class="">
                                    <h6>Wallet Transactions</h6>
                                </div>
                                <div class="">
                                    <small>Last 30 days</small>
                                </div>
                            </div>
                            }
                            <div class="row">
                                <div class="col-12">
                                    <canvas id="walletComparisonChart" width="400" height="135"></canvas>
                                </div>
                            </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </section>

        

        <section class="mb-5">
          <div class="row">
         
              <div class="col-12 col-md">
              {isLoadingtopcustomer?
               <div style={{ left: "15%", position: "absolute" }}>
               <ReactLoading type={"spin"} color={"#000000"} height={100} width={30} />
           </div>
              :
                <div class="card shadow-sm">
                  <div class="card-body">
                 

                    <div class="d-flex justify-content-between">
                        <div class="">
                            <h6>Top Customer</h6>
                        </div>
                        <div class="">
                            <small>Total shipment booked in last 30 days</small>
                        </div>
                    </div>

                  
                    <table class="table table-bordered">
                    
                   
                        <tbody> 
                          {topcustomers.map((sub)=>(
                            <tr>
                            <td>
                                <a >{sub.customerDetails.company_name?sub.customerDetails.company_name:sub.customerDetails.full_name}</a>
                            </td>
                            <td>{sub.totalShipments} <small>Shipments</small></td>
                            </tr>
                          ))}
                         
                         
                        </tbody>

                      </table>

                  </div>
                </div>
                }
              </div>
              
                <div class="col-12 col-md">
                {isLoadingvaluedcustomer?
               <div style={{ left: "50%", position: "absolute" }}>
               <ReactLoading type={"spin"} color={"#000000"} height={100} width={30} />
           </div>
              :
                    <div class="card shadow-sm">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div class="">
                                <h6>Valued Customer</h6>
                            </div>
                            <div class="">
                                <small>Total wallet recharged in last 30 days</small>
                            </div>
                        </div>
                        <table class="table table-bordered">
                            <tbody>
                            {valuedcustomers.map((sub)=>(
                            <tr>
                                <td>
                                <a >{sub.customerDetails.company_name?sub.customerDetails.company_name:sub.customerDetails.full_name}</a>
                                </td>
                                <td>INR {sub.totalAmount} <small>credited</small></td>
                            </tr>
                            ))}
                           
                            </tbody>
                        </table>
                    </div>
                    </div>
}
                </div>
                <div class="col-12 col-md">
                {IsLoadingtickets?
               <div style={{ left: "80%", position: "absolute" }}>
               <ReactLoading type={"spin"} color={"#000000"} height={100} width={30} />
           </div>
              :
                    <div class="card shadow-sm">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div class="">
                                <h6>Support Tickets</h6>
                            </div>
                            <div class="">
                                <small>Recent Tickets</small>
                            </div>
                        </div>
                        <table class="table table-bordered">
                            <tbody>
                              {tickets.map((sub)=>(
                            <tr>
                                <td>
                                    <small></small> <a>{sub.ticket_id}</a> 
                                </td>
                                <td>  <span class={sub.status==0?"badge rounded-pill bg-warning":sub.status==1?"badge rounded-pill bg-success":sub.status==2?"badge rounded-pill bg-danger":""}>{sub.status==0?"Pending":sub.status==1?"Answered":sub.status==2?"Closed":""}</span> </td>
                              
                            </tr>
                            ))}
                           
                            </tbody>
                        </table>
                    </div>
                    </div>
}
                </div>
          </div>
        </section>

            </div> */}

{/* <?php
            include('header.php');
        ?> */}

        {/* <?php
            include('left_nav.php');
        ?>
     */}
<Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
    <section class="home-section">
        <div class="">
                <div class="">
                    
<Sub_header/>
                    {/* <?php
                    include('sub_header.php');
                    ?>  */}

                    <section class="pt-3 top_state">
                        <div class="row">
                            <div class="col-12 col-sm-6 col-xl-3 mb-4">
                                <div class="card border-0 shadow">
                                    <div class="card-body">
                                        <div class="row d-block d-xl-flex align-items-center">
                                            <div class="col-6 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                                <div class="icon-shape icon-shape-home rounded me-4 me-sm-0">
                                                    <i class="fa fa-cube fa-2x" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <div class="col-6 col-xl-7 px-xl-0">
                                                <div class="d-none d-sm-block">
                                                    <h2 class="h6 text-gray-400 mb-0">Shipments</h2>
                                                    <h3 class="fw-extrabold mb-2">
                                                        <a href="">{tochartstate.shipment_count}</a>
                                                    </h3>
                                                </div>
                                                <small class="d-flex align-items-center text-gray-500">
                                                  From {date.start_date} To {date.end_date}
                                                </small> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-xl-3 mb-4">
                                <div class="card border-0 shadow">
                                    <div class="card-body">
                                        <div class="row d-block d-xl-flex align-items-center">
                                            <div class="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                                <div class="icon-shape icon-shape-home rounded me-4 me-sm-0">
                                                    <i class="fa fa-2x fa-users" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <div class="col-12 col-xl-7 px-xl-0">
                                                <div class="d-none d-sm-block">
                                                    <h2 class="h6 text-gray-400 mb-0">Users</h2>
                                                    <h3 class="fw-extrabold mb-2">
                                                        <a href="">{tochartstate.user_count}</a>
                                                    </h3>
                                                </div>
                                                <small class="d-flex align-items-center text-gray-500">
                                                From {date.start_date} To {date.end_date}
                                                </small> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-xl-3 mb-4">
                                <div class="card border-0 shadow">
                                    <div class="card-body">
                                        <div class="row d-block d-xl-flex align-items-center">
                                            <div class="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                                <div class="icon-shape icon-shape-home rounded me-4 me-sm-0">
                                                    <i class="fa fa-2x fa-first-order" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <div class="col-12 col-xl-7 px-xl-0">
                                                <div class="d-none d-sm-block">
                                                    <h2 class="h6 text-gray-400 mb-0"> Orders</h2>
                                                    <h3 class="fw-extrabold mb-2">
                                                        <a href="">{tochartstate.order_count}</a>
                                                    </h3>
                                                </div>
                                                <small class="text-gray-500">
                                                From {date.start_date} To {date.end_date}
                                                </small> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 col-xl-3 mb-4">
                                <div class="card border-0 shadow">
                                    <div class="card-body">
                                        <div class="row d-block d-xl-flex align-items-center">
                                            <div class="col-12 col-xl-5 text-xl-center mb-3 mb-xl-0 d-flex align-items-center justify-content-xl-center">
                                                <div class="icon-shape icon-shape-home rounded me-4 me-sm-0">
                                                    <i class="fa fa-2x fa-rupee" aria-hidden="true"></i>
                                                </div>
                                            </div>
                                            <div class="col-12 col-xl-7 px-xl-0">
                                                <div class="d-none d-sm-block">
                                                    <h2 class="h6 text-gray-400 mb-0"> Recharges</h2>
                                                    <h3 class="fw-extrabold mb-2">
                                                        <a href="">₹{(tochartstate.recharges).toFixed(2)}</a>
                                                    </h3>
                                                </div>
                                                <small class="text-gray-500">
                                                From {date.start_date} To {date.end_date}
                                                </small> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className="row mb-2">
                      <div className="col-4"></div>
                      <div className="col-4">  <input className="form-control" type="date" name="start_date" onChange={(e)=>handleChange(e)} value={date.start_date}></input></div>
                      <div className="col-4">
                      <input className="form-control" type="date" onChange={(e)=>handleChange(e)} name="end_date" value={date.end_date}></input>
                      </div>

                    </div>
                    
                    <section class="mb-3">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="shipment_card_st shadow-sm">
                                    <div class="shipment_card_st_bx">
                                      
                                    {isLoading?
               <div style={{ left: "20%", top: "45%", position: "absolute" }}>
               <ReactLoading type={"spin"} color={"#000000"} height={100} width={30} />
           </div>
              :
                            <div class="row">
                                <div class="col-12 col-md">
                                    <h5>Shipment Information</h5>
                                    <h1>{last30daysshipment.todayShipment} <small>{date.end_date}</small></h1>
                                    <h6>{last30daysshipment.Last30daysShipments} booked From {date.start_date} To {date.end_date}</h6>
                                </div>
                                <div class="col-12 col-md-4 text-end">
                                    <a href="">Shipment Report</a>
                                </div>
                            </div>
}
                                    </div>
                                    <canvas id="shipmentchart" width="400" height="100"></canvas>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="order_card_st shadow-sm">
                                    <div class="order_card_st_bx">
                                    {isLoadingorder?
               <div style={{ left: "70%", top: "45%", position: "absolute" }}>
               <ReactLoading type={"spin"} color={"#000000"} height={100} width={30} />
           </div>
              :
                            <div class="row">
                                <div class="col-12 col-md">
                                    <h5>Orders Information</h5>
                                    <h1>{last30daysorder.todayOrder} <small>{date.end_date}</small></h1>
                                    <h6>{last30daysorder.Last30daysOrder} added From {date.start_date} To {date.end_date}</h6>
                                </div>
                                <div class="col-12 col-md-4 text-end">
                                    <a href="">Orders Report</a>
                                </div>
                            </div>
}
                        </div>
                        <canvas id="orderchart" width="400" height="100"></canvas>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="mb-3">
                        <div class="row">
                            <div class="col-12 col-md-12">
                                <div class="card shadow-sm">
                                <div class="card-body">
                                    <div class="card-body">
                                        <div class="d-flex justify-content-between">
                                            <div class="">
                                                <h6>Shipments</h6>
                                            </div>
                                            <div class="">
                                                <small>From {date.start_date} To {date.end_date}</small>
                                            </div>
                                        </div>
                                        <div class="row">
                                        {isLoadingstatus?
               <div style={{ left: "46%", top: "10%", position: "absolute" }}>
               <ReactLoading type={"spin"} color={"#000000"} height={100} width={30} />
           </div>
              :
                                <div class="col-12 col-md-12">
                                    <canvas id="shipmentChart"  width="400" height="100"></canvas>
                                </div>
}
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    <section class="mb-3">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="card shadow-sm">
                                <div class="card-body">
                                    <div class="card-body">
                                    {isLoadingcarrier?
               <div style={{ left: "40%", top: "35%", position: "absolute" }}>
               <ReactLoading type={"spin"} color={"#000000"} height={100} width={30} />
           </div>
              :
                         <div class="d-flex justify-content-between">
                                <div class="">
                                    <h6>Carrier wise Shipments</h6>
                                </div>
                                <div class="">
                                    <small>From {date.start_date} To {date.end_date}</small>
                                </div>
                            </div>
}
                            <div class="row">
                                <div class="col-12">
                                    <canvas id="carrierBookingChart2" width="400" height="200"></canvas>
                                </div>
                            </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="card shadow-sm">
                                <div class="card-body">
                                    <div class="card-body">
                                    {isLoadingtransaction?
               <div style={{ left: "40%", top: "35%", position: "absolute" }}>
               <ReactLoading type={"spin"} color={"#000000"} height={100} width={30} />
           </div>
              :
                            <div class="d-flex justify-content-between">
                                <div class="">
                                    <h6>Wallet Transactions</h6>
                                </div>
                                <div class="">
                                    <small>From {date.start_date} To {date.end_date}</small>
                                </div>
                            </div>
                            }
                            <div class="row">
                                <div class="col-12">
                                    <canvas id="walletComparisonChart" width="400" height="135"></canvas>
                                </div>
                            </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    

                    <section class="mb-5">
          <div class="row">
         
              <div class="col-12 col-md">
              {isLoadingtopcustomer?
               <div style={{ left: "15%", position: "absolute" }}>
               <ReactLoading type={"spin"} color={"#000000"} height={100} width={30} />
           </div>
              :
                <div class="card shadow-sm">
                  <div class="card-body">
                 

                    <div class="d-flex justify-content-between">
                        <div class="">
                            <h6>Top Customer</h6>
                        </div>
                        <div class="">
                            <small>Total shipment booked From {date.start_date} To {date.end_date}</small>
                        </div>
                    </div>

                  
                    <table class="table table-bordered">
                    
                   
                        <tbody> 
                          {topcustomers.map((sub)=>(
                            <tr>
                            <td>
                                <a >{sub.customerDetails.company_name?sub.customerDetails.company_name:sub.customerDetails.full_name}</a>
                            </td>
                            <td>{sub.totalShipments} <small>Shipments</small></td>
                            </tr>
                          ))}
                         
                         
                        </tbody>

                      </table>

                  </div>
                </div>
                }
              </div>
              
                <div class="col-12 col-md">
                {isLoadingvaluedcustomer?
               <div style={{ left: "50%", position: "absolute" }}>
               <ReactLoading type={"spin"} color={"#000000"} height={100} width={30} />
           </div>
              :
                    <div class="card shadow-sm">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div class="">
                                <h6>Valued Customer</h6>
                            </div>
                            <div class="">
                                <small>Total wallet recharged From {date.start_date} To {date.end_date}</small>
                            </div>
                        </div>
                        <table class="table table-bordered">
                            <tbody>
                            {valuedcustomers.map((sub)=>(
                            <tr>
                                <td>
                                <a >{sub.customerDetails.company_name?sub.customerDetails.company_name:sub.customerDetails.full_name}</a>
                                </td>
                                <td>INR {sub.totalAmount} <small>credited</small></td>
                            </tr>
                            ))}
                           
                            </tbody>
                        </table>
                    </div>
                    </div>
}
                </div>
                <div class="col-12 col-md">
                {IsLoadingtickets?
               <div style={{ left: "80%", position: "absolute" }}>
               <ReactLoading type={"spin"} color={"#000000"} height={100} width={30} />
           </div>
              :
                    <div class="card shadow-sm">
                    <div class="card-body">
                        <div class="d-flex justify-content-between">
                            <div class="">
                                <h6>Support Tickets</h6>
                            </div>
                            <div class="">
                                <small>Recent Tickets</small>
                            </div>
                        </div>
                        <table class="table table-bordered">
                            <tbody>
                              {tickets.map((sub)=>(
                            <tr>
                                <td>
                                    <small></small> <a>{sub.ticket_id}</a> 
                                </td>
                                <td>  <span class={sub.status==0?"badge rounded-pill bg-warning":sub.status==1?"badge rounded-pill bg-success":sub.status==2?"badge rounded-pill bg-danger":""}>{sub.status==0?"Pending":sub.status==1?"Answered":sub.status==2?"Closed":""}</span> </td>
                              
                            </tr>
                            ))}
                           
                            </tbody>
                        </table>
                    </div>
                    </div>
}
                </div>
          </div>
        </section>
                </div>
        </div>
    </section>
         
        

        


            
                    



       
            {/* <?php
            include('footer.php');
            ?>   */}
            </div>
            
  )
}

export default Index