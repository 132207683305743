import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"
import exportValue from "../../apiconfig";
import Left_panel from '../Left_panel';
import Sub_header from '../Sub_header';
import '../../components/loader.css';
import { useNavigate } from 'react-router-dom'
import TablePagination from '@mui/material/TablePagination';
const Weight_discrepancies = () => {
    let navigate = useNavigate();
    const search1 = window.location.search;
    const params = new URLSearchParams(search1);
    let search= params.get('search')
    const [otherStates,setOtherState] = React.useState({activePage:1,rowsPerPage:500,page:0,total_count:0,onload:true});
    const [carrierList, setcarrierList] = useState([]);
    const[editmodals,setEditModals] = React.useState({show: false, carrier_id:"" ,carrier_name:"",carrier_image:"",})
    const[addImg,setAddImg]=React.useState({uploadPhoto:""})
    const [stateSave, setSaveState] = React.useState({ uploadimg: ""});
     const[statusState, setstatusState] = React.useState({status:1})
    const [uploadModalState, setUploadModalState] = useState({ show: false,excel_fle:"" });
    const [selectCarrier, setSelectCarrier] = useState({ carrier_id:"" });


  console.log("statusState",statusState)
  
     const [isImageSelected, setIsImageSelected] = React.useState(false);
     const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    const homeSection = document.querySelector('.home-section');
    if (homeSection) {
        homeSection.classList.toggle('sidebar-closed');
    }
};
  
    useEffect(() => {
        getCarrierList();
    }, [])

    const getCarrierList = (index=0) => {


        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/weight_discrepancies_file_lists`;
        let sendData = {  dstatus:1,
            indexValue:index,
            limit:otherStates.rowsPerPage};
       
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            console.log("res ", res);
            if(index==0  && otherStates.onload) {
                setOtherState({...otherStates,total_count:res.data.dataCount})         
    }
            setcarrierList(res.data.output)


        }).catch((e) => {


        });
    }
     
    const handleEditSubmit = (carrier_id) => {
        // console.log("id",editmodals.web_testimonial_id)
      
         console.log("submit1",{carrier_id:editmodals.carrier_id, carrier_name:editmodals.carrier_name,carrier_image: stateSave.uploadimg});
     

        const fd = new FormData();
        fd.append("carrier_id", editmodals.carrier_id);
        fd.append("carrier_name", editmodals.carrier_name);
        fd.append("carrier_image", stateSave.uploadimg);
      
        if (stateSave.uploadimg!= null && isImageSelected  == false) {
          fd.append('carrier_image',stateSave.uploadimg)
        }
        else if (isImageSelected  == true) {
          fd.append("carrier_image",stateSave.uploadimg[0],stateSave.uploadimg.name)
        }

        console.log("hey man",fd);
       if(carrier_id!=undefined && carrier_id!=""){
        if(editmodals.carrier_name!="" && editmodals.carrier_image!=''){
        axios.post(exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/edit_carrier_list`,  fd , { headers: exportValue.headers }).then((result)=>{
                    console.log("result is i ",result);
                    // state.isLoading = false
                    setEditModals({...editmodals,show:false})
                    if(result){
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'bottom-end',
                            showConfirmButton: false,
                            timer: 3000
                          })
                          
                          Toast.fire({
                            background:"#206bc4",
                            type: 'success',
                            title: "Updated Sucessfully",
                            color:"white"
                          });
                     }
                     else{
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'bottom-end',
                            showConfirmButton: false,
                            timer: 5000
                          })
                          
                          Toast.fire({
                            background:"#e63900",
                            type: 'error',
                            title: "Something Went Wrong",
                            color:"white"
                          });
                        }
                        getCarrierList()
                        // setaddState({web_body_id:"",body_heading:"",pic:"right",body_sub_heading:"",body_img:"",body_content:"",body_link_title:""})
                    
                    
                    }).catch((e) => {
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'bottom-end',
                            showConfirmButton: false,
                            timer: 5000
                          })
                          
                          Toast.fire({
                            background:"#e63900",
                            type: 'error',
                            title: "Something Went Wrong",
                            color:"white"
                          });
                     
                      console.log("----error:   ", e);
                    })
                }
                else{
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 2000
                      })
                      
                      Toast.fire({
                        background:"#e63900",
                        type: 'error',
                        title: "Please Fill the details",
                        color:"white"
                      });
                }
                }
                    else{
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'bottom-end',
                            showConfirmButton: false,
                            timer: 5000
                          })
                          
                          Toast.fire({
                            background:"#e63900",
                            type: 'error',
                            title: "Something Went Wrong",
                            color:"white"
                          });
                    }
    
    }

    const handleEditChange = (event) => {
       // event.persist()
        console.log("event is v",event.target.name);
        setEditModals({
            ...editmodals,
            [event.target.name]: event.target.value,
        })
    }

    const fileSelectedHandleredit = (event) => {
        setIsImageSelected(true);
        if (event.target.type == "file") {
        setSaveState({
              ...stateSave,
              uploadimg: event.target.files,
            });
            if(event.target.name == "carrier_image") {
                setAddImg({...addImg,uploadPhoto:URL.createObjectURL(event.target.files[0])})
                console.log("Add",addImg)
              }

      }};

      const removeImage = () => {
        console.log("on cick");
        setEditModals({...editmodals,carrier_image:""})
        setAddImg({...addImg,uploadPhoto:""})
        setSaveState({...stateSave,uploadimg:""})
        
      }

      const handlePageChange = (event,newPage) =>{
        console.log("newpage",newPage)
        setOtherState({...otherStates,page:newPage})
        //console.log("newPage ", newPage);
        getCarrierList(newPage);
        
        // searchUser(state.searchValue,newPage)
    }
    const handleChangeRowsPerPage = (event,newPage) => {
        console.log("event ", event);
        setOtherState({...otherStates, rowsPerPage:+event.target.value, page:0})
       
    }

    
    const changeStatus = (updateData) => {


        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/update_status_carrier`;
        let sendData = {carrier_id:updateData.carrier_id , status:updateData.status };
        console.log("sendData",sendData)
       
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            console.log("res ", res);
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              
              Toast.fire({
                background:"#206bc4",
                type: 'success',
                title: "Updated Sucessfully",
                color:"white"
              });
          }).catch((e) => {
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 5000
              })
              
              Toast.fire({
                background:"#e63900",
                type: 'error',
                title: "Something Went Wrong",
                color:"white"
              });
               
            
               });
    }
    const csvinputEditHandleChange = (e) => {        
      if (e.target.type == "file") {            
          setUploadModalState({ ...uploadModalState, [e.target.name]: e.target.files });
      } else {
          setUploadModalState({ ...uploadModalState, [e.target.name]: e.target.value });
      }
    }
    const uploadPincodeFile= () => {
   
      if (uploadModalState.excel_fle != undefined && uploadModalState.excel_fle != '') {
         // var patt1 = /\.([0-9a-z]+)(?:[\?#]|$)/i;
          let fileName = uploadModalState.excel_fle[0].name;
         // console.log("fileName =======>                 ",fileName);
         
         // console.log("file =======>                 ",fileName.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)[0]);
         // return 
          if(fileName.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)[0] == ".csv"){
      let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/upload_weight_discrepancies_file`;
       let bodyFormData = new FormData()
      // console.log("bb", editState);
      //  bodyFormData.append("pincode", editState.country)
    //    bodyFormData.append("carrier_id",selectCarrier.carrier_id )
       bodyFormData.append("excel_fle", uploadModalState.excel_fle[0]);
       
     // let bodyData = {country_id:country_id,csvFile:uploadModalState.csv_fle}
      console.log("bb", bodyFormData);
      axios.post(full_api, bodyFormData, {
          headers:exportValue.headers
      }).then((res) => {
          console.log("res ", res);

          setUploadModalState({...uploadModalState, show: false });
        //  setEditState({ ...editState, state_name: "", state_id: "" })

         
          if (res.status == 200 && res.data.status == "success") {
           
             // setTimeout(getStateList(0,true), 5000);
             const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 3000
          })
              Toast.fire({
                  background: "#206bc4",
                  type: 'success',
                  title: "Bulk Import Process Starts",
                  color: "white"
              });
              setTimeout(()=>{
                window.location.reload();
                //  navigate(`/weight_discrepancies_log/${res.data.record_id}`)
              },1000)
              
          }
          
          else {
              Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: 'Something went wrong!',

              })
          }

          // navigate('/categories')
          // let newCategory = res.data.category;
          //setState(state.concat([newCategory]))
      }).catch((e) => {

          Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Something went wrong!',

          })
      });
  }else {
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 3000
  })
      Toast.fire({
          background: "#8a2be2",
          type: 'error',
          title: "Please choose CSV file!",
          color: "white"
      });
  }
  }else {
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 3000
  })
           Toast.fire({
                  background: "#10b93b",
                  type: 'error',
                  title: "Please choose a file!",
                  color: "white"
              });
  }

 

}

  return (
    <div>
          <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
       
       <section class="home-section">
               {/* <?php
               include('sub_header.php');
               ?> 
            */}
       <Sub_header/>

<div class="row py-4">
    <div class="col-12 col-md">
        <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <ol class="breadcrumb breadcrumb-dark breadcrumb-transparent">
                {/* <li class="breadcrumb-item">
                    <a href="#">
                        <svg class="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                    </a>
                </li> */}
                {/* <li class="breadcrumb-item active" aria-current="page"></li> */}
            </ol>
        </nav>
        <h2 class="h4">Weight Discrepancies</h2>
    </div>
    <div class="col-12 col-md-5">
        
    </div>
    <div class="col-12 col-md text-end">
        <a  class="btn btn-sm btn-gray-800 d-inline-flex align-items-center" data-bs-toggle="modal" data-bs-target="#import_pincode_special">
            <svg class="icon icon-xs me-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
            Upload Weight List
        </a>
    </div>
</div>
<div class="modal" id="import_pincode_special" tabIndex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Import Weight List</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
      </div>
      <div class="modal-body">
        <div class="alert alert-success">
          <strong>Alert!</strong> 
          <ul>
            <li>This action can not be undone.</li>
            <li>Doing the corrupt or broken variable file can cause system malfunction.</li>
            <li>Download sample file and setup the file in correct format to import</li>
          </ul>
        </div>
        <div class="d-flex justify-content-between">
            <div class="">Download Sample File</div>
            <div class="ps-2 "><a href="https://cdn.shipdartexpress.com/sample-template/weight_discrepances.csv" target="_blank"  class="btn btn-outline-primary btn-sm">Downlaod Sample File</a></div>
        </div>
        <hr/>
    
        <div class="mb-3 mt-3">
          <label for="" class="form-label">File: <span class="text-danger">*</span></label>
          <input type="file" class="form-control" id="" placeholder="Enter Banner" name="excel_fle" onChange={(e) => csvinputEditHandleChange(e)}/>
        </div>
      </div>
      <div class="modal-footer">
        <a  class="btn btn-primary"  onClick={() =>uploadPincodeFile()}>Submit</a>
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
<section>

                                 {/* edit modal */}
                                     <Modal show={editmodals.show} onHide={()=>setEditModals({...editmodals,show:false})} >
                                    <Modal.Header closeButton>
                                    <h4 class=" modal-title">Update Carrier Settings</h4>
                                    </Modal.Header>
                                    <Modal.Body>
                                    <div class="modal-body">
        <div class="mb-3 mt-3">
          <label for="Carrier Name" class="form-label">Carrier Name:</label>
          <input type="text" class="form-control" id="Carrier Name" placeholder="Enter Carrier Name" name="carrier_name" value={editmodals.carrier_name} required onChange={handleEditChange}/>
        </div>
        <div class="mb-3 mt-3">
        {(editmodals.carrier_image == "" && addImg.uploadPhoto == "") ? 
                    <>
                    <label for="email" class="form-label">Image <small class="text-muted">(750px x 750px max)</small></label>
                       <input type="file" class="form-control" id="email" placeholder="Enter email" name="carrier_image" onChange={(event)=>fileSelectedHandleredit(event)
                      }/>
                      </>
                   

                       
                        
                         :
                         <div style={{width:"170px",marginRight:"7px"}} className="imageLogo1">
                           <div className="overlay1"> 
                           <i className="fa fa-trash-o icon1" onClick={()=>removeImage()}></i>
                           </div>
                           {(editmodals.carrier_image != '') ?
                        <img src={`https://cdn.shipdartexpress.com/images/${editmodals.carrier_image}`} style={{width:"100%",height:"200px"}} className="mt-3"/> 
                        :(addImg.uploadPhoto!= '')?
                        <img src={addImg.uploadPhoto} style={{width:"100%",height:"200px"}} className="mt-3"/> 
                        :""
                           }
                        </div>

                      }
        </div>
        <hr/>
        
        
        <h5>Settings</h5>
        {/* <form autocomplete="off"> */}
        <div class="mb-3 mt-3">
          <label for="" class="form-label">Current System Mode:</label><br/>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="sand" value="option1" checked />
            <label class="form-check-label" for="sand">Sandbox</label>
          </div>
          <div class="form-check form-check-inline">
            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="lvies" value="option2" />
            <label class="form-check-label" for="lvies">Product Live</label>
          </div>
        </div>
        <div class="mb-3 mt-3">
          <label for="" class="form-label">Private ID:</label>
          <input type="text" class="form-control pw2" id="" placeholder="Enter Private ID" name="" value="298234ksldk99430349" />
        </div>
        <div class="mb-3 mt-3">
          <label for="" class="form-label">Private Key:</label>
          <input type="text" class="form-control pw2" id="" placeholder="Enter Private Key" name="" value="298234ksldk99430349" />
        </div>
      </div>
                                    </Modal.Body>
                                    <Button variant="primary" className='mb-3 mx-3' 
                                        onClick={() =>
                                            handleEditSubmit(editmodals.carrier_id)
                                        }>
                                        Submit
                                      </Button>
                                     
                                  
                                  
                                  </Modal>
           

</section>

        
        
        <div class="card card-body border-0 shadow table-wrapper table-responsive">
            <table class="table table-hover carr_list_ad">
                <thead>
                    <tr>
                        <th class="border-gray-200">#</th>
                        <th class="border-gray-200">#ID</th>	
                        <th class="border-gray-200">File Name</th>
                        <th class="border-gray-200">Total Records</th>
                        <th class="border-gray-200">Uploaded Records</th>
                        <th class="border-gray-200">Status</th>
                        <th class="border-gray-200">Action</th>
                    </tr>
                </thead>
                {carrierList.map((sub,index)=>(
                <tbody>
                    {/* <!-- Item --> */}
                    <tr>
                        <td>
                            {index+1}
                        </td>
                        <td>
                            <span class="fw-normal"><a href="">{sub.record_id}</a></span>
                        </td>                        
                        <td>
                            <strong>{sub.file_name}</strong>
                        </td>  
                        <td>
                            <strong>{sub.total_record}</strong>
                        </td>                      
                        <td>
                            <strong>{sub.uploaded_record}</strong>
                        </td>  
                        <td>
                          {sub.current_status==1?"Completed":"Pending"}
                        </td>
                        <td>
                         
                          <a href={`/weight_discrepancies_log/${sub.record_id}`}>Log</a>
                        </td>
                    </tr>
                                                
                </tbody>
                ))}
            </table>
            <TablePagination
                component="div"
                rowsPerPageOptions={[500,800,1000]}
                count={otherStates.total_count}
                page={otherStates.page}
                onPageChange={handlePageChange}
                rowsPerPage={otherStates.rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
               
                        />
        </div> 
                

</section>




    </div>
  )
}

export default Weight_discrepancies
