import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import exportValue from "../apiconfig";
import { borderRadius } from "@mui/system";
import moment from "moment";
import Moment from "react-moment";

const Left_panel = ({ isSidebarOpen, toggleSidebar , props }) => {
  const support = exportValue.SUPPORT
  const reports = exportValue.REPORTS

  let navigate = useNavigate();
 
  const CUSTOMER_NEW_REGISTER = exportValue.CUSTOMER_NEW_REGISTER
  const SMS_GATEWAY = exportValue.SMS_GATEWAY
  const CUSTOMER_KYC = exportValue.CUSTOMER_KYC


  const[state,setState]= React.useState({notification:[],count:0})
  const [submenuVisible, setSubmenuVisible] = useState(false);
  const [submenushipmentVisible, setSubmenushipmentVisible] = useState(false);
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const [isSubMenuOpenbilling, setIsSubMenuOpenbilling] = useState(false);
  const [isSubMenuOpenreport, setIsSubMenuOpenreport] = useState(false);
  const [inventory, setinventory] = useState(false);

  const [isSubMenuOpensetting, setIsSubMenuOpensetting] = useState(false);
  const[issetting,setIssetting] =useState(false);
  // const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  // const toggleSidebar = () => {
  //     setIsSidebarOpen(!isSidebarOpen);
  // };


    // Function to toggle sub-menu
    const toggleSubMenu = () => {
        setIsSubMenuOpen(!isSubMenuOpen);
    };
    const toggleSubMenubilling = () => {
      setIsSubMenuOpenbilling(!isSubMenuOpenbilling);
  };
  const toggleSubMenureport = () => {
    setIsSubMenuOpenreport(!isSubMenuOpenreport);
};
const toggleinventory = () => {
  setinventory(!inventory);
};
const toggleSubMenusetting = () => {
  setIsSubMenuOpensetting(!isSubMenuOpensetting);
};
const togglesetting = () => {
  setIssetting(!issetting);
};  
// Function to navigate to create order page
    const navigateToCreateOrder = () => {
        navigate('/customer/create_order_1?value=0');
    };

  const handleSubMenuClick = () => {
    setSubmenuVisible(!submenuVisible);
  };
  const handleshipmentSubMenuClick = () => {
    setSubmenushipmentVisible(!submenushipmentVisible);
  };
  
  let loginDetail = localStorage.getItem('admin_login');
 // const [state, setState] = useState({ full_name: "" });
 
  //console.log("e => ", loginDetail);
  if(loginDetail == null) {
    window.location.href = '/login';
    //navigate('/login')
  }else {    
    loginDetail = JSON.parse(localStorage.getItem('admin_login'));
   // setState(JSON.parse(loginDetail))
  }
  let admindata = {};
if (localStorage.getItem('admin_login')) {
  admindata = JSON.parse(localStorage.getItem('admin_login'));
  console.log("admindata------>  -  ",admindata);
  //navigate('/home') 
}

  
const logout = () =>{
  console.log("userDetail ===>   ");

  let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/user_logout`;
    let sendData = {admin_id:loginDetail.admin_id};
    console.log("bb", sendData);
    axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
        console.log("res ", res);
        if (res.data.status==true) {
          localStorage.removeItem('admin_login');
    localStorage.clear();
    navigate('/login')
 
        }else {
          // Toast.fire({
          //   background:"rgb(231, 91, 75)",
          //   type: 'unsuccess',
          //   title: res.data.message ,
          //   color:"white"
          // });
           
        }

    }).catch((e) => {


    });
  

}

const notification=()=>{
     
  let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/notification_find`;
 let send={
  find:"",

  indexValue: 0,
  limit:5
 }
  
  axios.post(full_api, send,{ headers: exportValue.headers }).then((res) => {
     // console.log("res ", res);
   
      setState({...state,notification:res.data.output,count:res.data.count})
     // console.log("res",res)
    
     
  }).catch((e) => {

  });
}

React.useEffect(()=>{
 // notification()
},[])



  return (
    <>
   {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu">
              <span className={width<=800?"navbar-toggler-icon mt-3":"d-none"}><i className="fa fa-bars" aria-hidden="true"></i></span>
            </button>
<nav id="sidebarMenu" className={width<=800?"sidebar d-lg-block  bg-gray-800 text-white collapse":"sidebar d-block  bg-gray-800 text-white collapse"} data-simplebar>
  <div className="sidebar-inner px-4 pt-3">
    <div className="p-3">
      {width<=800?
      <div className='row'>
        <div className='col-8'>
        <h4><a href="/">Admin Panel</a></h4>
        </div>
        <div className='col-2'></div>
        <div className='col-2'><i className='fa fa-close  close navbar-toggler' type="button" data-bs-toggle="collapse" data-bs-target="#sidebarMenu"></i></div>
        </div>
      :<h4><a href="/">Admin Panel</a></h4>}
    </div>
    <div>
    <ul className="nav flex-column pt-3 pt-md-0">
      <li className={value==0?"nav-item  active ":"nav-item"}>
        <Link to="/" className="nav-link">
          <span className="sidebar-icon">
            <i className="fa fa-pie-chart" aria-hidden="true"></i>
          </span> 
          <span className="sidebar-text">Dashboard</span>
        </Link>
      </li>
      <li className="nav-item" >
        <span
          className="nav-link  collapsed  d-flex justify-content-between align-items-center"
          data-bs-toggle="collapse" data-bs-target="#settings_gen">
          <span>
            <span className="sidebar-icon">
              <i className="fa fa-cog" aria-hidden="true"></i>
            </span> 
            <span className="sidebar-text">General Setting</span>
          </span>
          <span className="link-arrow ">
            <svg className="icon icon-sm" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
          </span>
        </span>
        <div className={value==1 || value==2 || value==3 || value==4 || value==5 ?"multi-level collapse show":"multi-level collapse"}
          role="list" id="settings_gen" aria-expanded="false">
          <ul className="flex-column nav ">
            <li className={value==1?"nav-item active":"nav-item"} >
              <Link className="nav-link " to="/system_settings" >
                <span className="sidebar-text "  ><i className="fa fa-angle-right icon_list" aria-hidden="true"></i> System Settings</span>
              </Link>
            </li>
            <li className={value==2?"nav-item active":"nav-item"}>
              <Link className="nav-link" to="/master_settings">
                <span className="sidebar-text"><i className="fa fa-angle-right icon_list" aria-hidden="true"></i> Master Settings</span>
              </Link>
            </li>
            <li className={value==3?"nav-item active":"nav-item"}>
              <Link className="nav-link" to="/social_settings">
                <span className="sidebar-text"><i className="fa fa-angle-right icon_list" aria-hidden="true"></i> Social Settings</span>
              </Link>
            </li>
            <li className={value==4?"nav-item active":"nav-item"}>
              <Link className="nav-link" to="/payment_settings">
                <span className="sidebar-text"><i className="fa fa-angle-right icon_list" aria-hidden="true"></i> Payment Settings</span>
              </Link>
            </li>
            <li className={value==5?"nav-item active":"nav-item"}>
              <Link className="nav-link" to="/smtp_settings">
                <span className="sidebar-text"><i className="fa fa-angle-right icon_list" aria-hidden="true"></i> SMTP Email Settings</span>
              </Link>
            </li>
          </ul>
        </div>
      </li>
      <li className="nav-item">
        <span
          className="nav-link  collapsed  d-flex justify-content-between align-items-center"
          data-bs-toggle="collapse" data-bs-target="#leftmenubar-111">
          <span>
            <span className="sidebar-icon">
              <i className="fa fa-cubes" aria-hidden="true"></i>
            </span> 
            <span className="sidebar-text">Shipments</span>
          </span>
          <span className="link-arrow">
            <svg className="icon icon-sm" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
          </span>
        </span>
        <div className={value==6 ?"multi-level collapse show":"multi-level collapse"}
          role="list" id="leftmenubar-111" aria-expanded="false">
          <ul className="flex-column nav">
            <li className={value==6?"nav-item active":"nav-item"}>
              <Link className="nav-link" to="/shipments">
                <span className="sidebar-text"><i className="fa fa-angle-right icon_list" aria-hidden="true"></i> All Shipments</span>
              </Link>
            </li>
          </ul>
        </div>
      </li>
      <li className="nav-item">
        <span
          className="nav-link  collapsed  d-flex justify-content-between align-items-center"
          data-bs-toggle="collapse" data-bs-target="#leftmenubar-1117">
          <span>
            <span className="sidebar-icon">
              <i className="fa fa-money" aria-hidden="true"></i>
            </span> 
            <span className="sidebar-text">Payment</span>
          </span>
          <span className="link-arrow">
            <svg className="icon icon-sm" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
          </span>
        </span>
        <div className={value==7 || value==22 || value==23?"multi-level collapse show":"multi-level collapse"}
          role="list" id="leftmenubar-1117" aria-expanded="false">
          <ul className="flex-column nav">
            <li className={value==7?"nav-item active":"nav-item"}>
              <Link className="nav-link" to="/payments">
                <span className="sidebar-text"><i className="fa fa-angle-right icon_list" aria-hidden="true"></i> Shipment Payments</span>
              </Link>
              
            </li>
            <li className={value==22?"nav-item active":"nav-item"}>
              <Link className="nav-link" to="/admin_payments">
                <span className="sidebar-text"><i className="fa fa-angle-right icon_list" aria-hidden="true"></i> Admin Payment</span>
              </Link>
              
            </li>
            <li className={value==23?"nav-item active":"nav-item"}>
              <Link className="nav-link" to="/transporter_payments">
                <span className="sidebar-text"><i className="fa fa-angle-right icon_list" aria-hidden="true"></i> Transporter Payment</span>
              </Link>
              
            </li>
          </ul>
        </div>
      </li>
  
      <li className="nav-item">
        <span
          className="nav-link  collapsed  d-flex justify-content-between align-items-center"
          data-bs-toggle="collapse" data-bs-target="#leftmenubar-113">
          <span>
            <span className="sidebar-icon">
              <i className="fa fa-users" aria-hidden="true"></i>
            </span> 
            <span className="sidebar-text">Users</span>
          </span>
          <span className="link-arrow">
            <svg className="icon icon-sm" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
          </span>
        </span>
        <div className={value==9 ?"multi-level collapse show":"multi-level collapse"}
          role="list" id="leftmenubar-113" aria-expanded="false">
          <ul className="flex-column nav">
            <li className={value==9?"nav-item active":"nav-item"}>
              <Link className="nav-link" to="/users">
                <span className="sidebar-text">All Users</span>
              </Link>
            </li>
           
          </ul>
        </div>
      </li>
  
  
      <li className="nav-item">
        <span
          className="nav-link  collapsed  d-flex justify-content-between align-items-center"
          data-bs-toggle="collapse" data-bs-target="#leftmenubar-116">
          <span>
            <span className="sidebar-icon">
              <i className="fa fa-map" aria-hidden="true"></i>
            </span> 
            <span className="sidebar-text">Location</span>
          </span>
          <span className="link-arrow">
            <svg className="icon icon-sm" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
          </span>
        </span>
        <div className={value==12 ?"multi-level collapse show":"multi-level collapse"}
          role="list" id="leftmenubar-116" aria-expanded="false">
          <ul className="flex-column nav">
            <li className={value==12?"nav-item active":"nav-item"}>
              <Link className="nav-link" to="/country_list">
                <span className="sidebar-text">Country List</span>
              </Link>
            </li>

          </ul>
        </div>
      </li>
      <li className="nav-item">
        <span
          className="nav-link  collapsed  d-flex justify-content-between align-items-center"
          data-bs-toggle="collapse" data-bs-target="#leftmenubar-117">
          <span>
            <span className="sidebar-icon">
              <i className="fa fa-envelope" aria-hidden="true"></i>
            </span> 
            <span className="sidebar-text">Email Template</span>
          </span>
          <span className="link-arrow">
            <svg className="icon icon-sm" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
          </span>
        </span>
        <div className={value==13 ?"multi-level collapse show":"multi-level collapse"}
          role="list" id="leftmenubar-117" aria-expanded="false">
          <ul className="flex-column nav">
            <li className={value==13?"nav-item active":"nav-item"}>
              <Link className="nav-link" to="/email_template">
                <span className="sidebar-text">Templates</span>
              </Link>
            </li>
          </ul>
        </div>
      </li>
      <li className="nav-item">
        <span
          className="nav-link  collapsed  d-flex justify-content-between align-items-center"
          data-bs-toggle="collapse" data-bs-target="#leftmenubar-1181">
          <span>
            <span className="sidebar-icon">
              <i className="fa fa-support" aria-hidden="true"></i>
            </span> 
            <span className="sidebar-text">Spam Report</span>
          </span>
          <span className="link-arrow">
            <svg className="icon icon-sm" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path></svg>
          </span>
        </span>
        <div className={value==14 ?"multi-level collapse show":"multi-level collapse"}
          role="list" id="leftmenubar-1181" aria-expanded="false">
          <ul className="flex-column nav">
            <li className={value==14?"nav-item active":"nav-item"}>
              <Link className="nav-link" to="/spam_report">
                <span className="sidebar-text">All Reports</span>
              </Link>
            </li>
          </ul>
        </div>
      </li>
    
     
    


      <li role="separator" className="dropdown-divider mt-4 mb-3 border-gray-700"></li>
      
    </ul>
    </div>
  </div>
</nav> */}
<aside class="sidebar ">
   <div class="sidebar-header">
      <img src="assets/img/logo.png" alt="logo" />
      <h2>Shipdartexpress</h2>
   </div>
   <ul class="sidebar-links">
      <h4>
         <span>Main Menu</span>
         <div class="menu-separator"></div>
      </h4>
      {admindata.admin_type!=5?
      <li class="active">
         <a href="/"><i class='bx bx-grid-alt' ></i>Dashboard</a>
      </li>
      :""}
      {admindata.admin_type==2 ?
        <li class="submenu ">
          <a href="/shipments">
          <i class='bx bx-package' ></i> Shipments 
          <span><i class='bx bx-chevron-down' ></i></span>
          </a>
          <ul class="submenu-links">
            <li class="active"><a href="/shipments" class="active"><i class='bx bx-circle' ></i> All Shipments</a></li>
            <li><a href="/delivered_shipments"><i class='bx bx-circle' ></i> Delivered Shipments</a></li>
            <li><a href="/undelivered_shipments"><i class='bx bx-circle' ></i> Undelivered Shipments</a></li>
          </ul>
        </li>
        :admindata.admin_type==3 && admindata.previlage&& admindata.previlage.shipments!=undefined && admindata.previlage.shipments!=false? 
        <li class="submenu ">
        <a href="/shipments">
        <i class='bx bx-package' ></i> Shipments 
        <span><i class='bx bx-chevron-down' ></i></span>
        </a>
        <ul class="submenu-links">
          <li class="active"><a href="/shipments" class="active"><i class='bx bx-circle' ></i> All Shipments</a></li>
          <li><a href="/delivered_shipments"><i class='bx bx-circle' ></i> Delivered Shipments</a></li>
          <li><a href="/undelivered_shipments"><i class='bx bx-circle' ></i> Undelivered Shipments</a></li>
        </ul>
      </li>
      :""}

{admindata.admin_type==2 ?
      <li>
         <a href="/customers"><i class='bx bx-user' ></i>Customers</a>
      </li>
      :admindata.admin_type==3 && admindata.previlage && admindata.previlage.customers!=undefined && admindata.previlage.customers!=false? 

       <li>
         <a href="/customers"><i class='bx bx-user' ></i>Customers</a>
      </li>
      :""}
{admindata.admin_type==2?     
        <li class="submenu">
          <a href="/payments">
          <i class='bx bx-wallet' ></i> Payments 
          <span><i class='bx bx-chevron-down' ></i></span>
          </a>
          <ul class="submenu-links">
            <li><a href="/payments"><i class='bx bx-circle' ></i> Shipment Payments</a></li>
            <li><a href="/user_cod_wallet"><i class='bx bx-circle' ></i> Customer COD Wallet Payments</a></li>
            <li><a href="/wallet_payments"><i class='bx bx-circle' ></i> Wallet Payments</a></li>
            <li><a href="/payment_gateway_payments"><i class='bx bx-circle' ></i> Wallet Recharge Payment Gateways</a></li>
            {/* <li><a href="#"><i class='bx bx-circle' ></i> COD Payouts</a></li> */}
          </ul>
        </li>
 :admindata.admin_type==3 &&admindata.previlage&& admindata.previlage.payments!=undefined && admindata.previlage.payments!=false?
 <li class="submenu">
          <a href="/payments">
          <i class='bx bx-wallet' ></i> Payments 
          <span><i class='bx bx-chevron-down' ></i></span>
          </a>
          <ul class="submenu-links">
            <li><a href="/payments"><i class='bx bx-circle' ></i> Shipment Payments</a></li>
            <li><a href="/user_cod_wallet"><i class='bx bx-circle' ></i> Customer COD Wallet Payments</a></li>
            <li><a href="/wallet_payments"><i class='bx bx-circle' ></i> Wallet Payments</a></li>
            <li><a href="/payment_gateway_payments"><i class='bx bx-circle' ></i> Wallet Recharge Payment Gateways</a></li>
            {/* <li><a href="#"><i class='bx bx-circle' ></i> COD Payouts</a></li> */}
          </ul>
        </li>
        :""}

{admindata.admin_type==2?
        <li class="submenu">
          <a href="/users">
          <i class='bx bx-user-check' ></i> Users Management 
          <span><i class='bx bx-chevron-down' ></i></span>
          </a>
          <ul class="submenu-links">
            <li><a href="/users"><i class='bx bx-circle' ></i> Staff Users</a></li>
            <li><a href="/dispatchers"><i class='bx bx-circle' ></i> Dispatchers</a></li>
          </ul>
        </li>
         :admindata.admin_type==3 && admindata.previlage.users!=undefined && admindata.previlage.users!=false?
         <li class="submenu">
          <a href="/users">
          <i class='bx bx-user-check' ></i> Users Management 
          <span><i class='bx bx-chevron-down' ></i></span>
          </a>
          <ul class="submenu-links">
            <li><a href="/users"><i class='bx bx-circle' ></i> Staff Users</a></li>
            <li><a href="/dispatchers"><i class='bx bx-circle' ></i> Dispatchers</a></li>
          </ul>
        </li>
        :""}


      <h4>
         <span>Tools</span>
         <div class="menu-separator"></div>
      </h4>
      <li>
         <a href="/weight_discrepancies"><i class='bx bx-cylinder' ></i>Weight Discrepancies</a>
      </li>
      <li>
         <a href="/zone_calculator"><i class='bx bx-calculator' ></i>Rate Calculator</a>
      </li>
      <li>
         <a href="/search_pincode"><i class='bx bx-search' ></i>Search Pincode</a>
      </li>


      <h4>
         <span>Other</span>
         <div class="menu-separator"></div>
      </h4>
      {support && admindata.admin_type!=4 && admindata.admin_type!=5?
      <li>
         <a href="/tickets"><i class='bx bx-support' ></i>Support</a>
      </li>
      :""}

      <li>
         <a href="/pending_kyc"><i class='bx bx-id-card' ></i>KYC Management</a>
      </li>

{reports && admindata.admin_type!=4 && admindata.admin_type!=5?
        <li class="submenu">
          <a href="/shipment_report">
          <i class='bx bx-receipt' ></i> Reports
          <span><i class='bx bx-chevron-down' ></i></span>
          </a>
          <ul class="submenu-links">
            <li><a href="/shipment_report"><i class='bx bx-circle' ></i> Shipment Reports</a></li>
            <li><a href="/missing_pincodes"><i class='bx bx-circle' ></i> Missing Pincode Reports</a></li>
            <li><a href="/invoices"><i class='bx bx-circle' ></i> Customer Invoices</a></li>
          </ul>
        </li>
:""}
    
    {admindata.admin_type==2?
      <h4>
         <span>Master</span>
         <div class="menu-separator"></div>
      </h4>
      :admindata.admin_type==3 && admindata.previlage.users!=undefined && admindata.previlage.users!=false?
      <h4>
         <span>Master</span>
         <div class="menu-separator"></div>
      </h4>
      :""}

{admindata.admin_type==2?
        <li class="submenu">
          <a href="/master_new">
          <i class='bx bx-hive' ></i> Master Settings
          <span><i class='bx bx-chevron-down' ></i></span>
          </a>
          <ul class="submenu-links">
            <li><a href="/master_new"><i class='bx bx-circle' ></i> Master Settings</a></li>
            <li><a href="/carrier_list"><i class='bx bx-circle' ></i> Carrier Managment</a></li>
            <li><a href="/zone_list"><i class='bx bx-circle' ></i> Zone Managment</a></li>
            <li><a href="/product"><i class='bx bx-circle' ></i> Product Managment</a></li>
            <li><a href="/servicable_carriers"><i class='bx bx-circle' ></i> Servicable Pincodes</a></li>
            <li><a href="/country_list"><i class='bx bx-circle' ></i> Location Managment</a></li>
          </ul>
        </li>
         :admindata.admin_type==3 && admindata.previlage.users!=undefined && admindata.previlage.users!=false?
         <li class="submenu">
         <a href="/master_new">
         <i class='bx bx-hive' ></i> Master Settings
         <span><i class='bx bx-chevron-down' ></i></span>
         </a>
         <ul class="submenu-links">
            <li><a href="/master_new"><i class='bx bx-circle' ></i> Master Settings</a></li>
            <li><a href="/carrier_list"><i class='bx bx-circle' ></i> Carrier Managment</a></li>
            <li><a href="/zone_list"><i class='bx bx-circle' ></i> Zone Managment</a></li>
            <li><a href="/product"><i class='bx bx-circle' ></i> Product Managment</a></li>
            <li><a href="/servicable_carriers"><i class='bx bx-circle' ></i> Servicable Pincodes</a></li>
            <li><a href="/country_list"><i class='bx bx-circle' ></i> Location Managment</a></li>
          </ul>
       </li>
       :""}

{admindata.admin_type==2?
      <h4>
         <span>Settings</span>
         <div class="menu-separator"></div>
      </h4>
     :admindata.admin_type==3 && admindata.previlage.system_settings!=undefined && admindata.previlage.system_settings!=false?
     <h4>
     <span>Settings</span>
     <div class="menu-separator"></div>
  </h4>
  :""}

      {admindata.admin_type==2?
      <li class="submenu">
          <a href="/system_settings">
          <i class='bx bx-cog' ></i> Settings
          <span><i class='bx bx-chevron-down' ></i></span>
          </a>
          <ul class="submenu-links">
            <li><a href="/system_settings"><i class='bx bx-circle' ></i> Company Settings</a></li>
            <li><a href="/system_settings"><i class='bx bx-circle' ></i> Social Settings</a></li>
            <li><a href="/system_settings"><i class='bx bx-circle' ></i> Payment Gateway Settings</a></li>
            <li><a href="/system_settings"><i class='bx bx-circle' ></i> SMTP Settings</a></li>
            <li><a href="/system_settings"><i class='bx bx-circle' ></i> Email Template Settings</a></li>
          </ul>
        </li>
        :admindata.admin_type==3 && admindata.previlage.system_settings!=undefined && admindata.previlage.system_settings!=false?
        <li class="submenu">
        <a href="/system_settings">
        <i class='bx bx-cog' ></i> Settings
        <span><i class='bx bx-chevron-down' ></i></span>
        </a>
        <ul class="submenu-links">
          <li><a href="/system_settings"><i class='bx bx-circle' ></i> Company Settings</a></li>
          <li><a href="/system_settings"><i class='bx bx-circle' ></i> Social Settings</a></li>
          <li><a href="/system_settings"><i class='bx bx-circle' ></i> Payment Gateway Settings</a></li>
          <li><a href="/system_settings"><i class='bx bx-circle' ></i> SMTP Settings</a></li>
          <li><a href="/system_settings"><i class='bx bx-circle' ></i> Email Template Settings</a></li>
        </ul>
      </li>
      :""}

{admindata.admin_type==4?
 <li class="submenu">
        <a href="/ship_1?value=0">
        <i class='bx bx-cog' ></i> Create Order
        <span><i class='bx bx-chevron-down' ></i></span>
        </a>
        <ul class="submenu-links">
          <li><a href="/orders"><i class='bx bx-circle' ></i> Order List</a></li>
          <li><a onClick={()=>navigate({
       pathname: '/ship_1',
       search: `value=0`,
     })}><i class='bx bx-circle' ></i> Create Shipment</a></li>
         
        </ul>
      </li>
      :""}


   </ul>
</aside>







    </>
  )
}

export default Left_panel